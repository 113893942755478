import React, { useMemo } from "react";
import calculatePossibleSphValues from "./Calculators/SphCalc";

function SmallLensSphProperties({ lens }) {
  const possibleSphValues = useMemo(
    () => calculatePossibleSphValues(lens),
    [lens]
  );

  const isInExclusionList = (lensName) => {
    const exclusionList = [
      "Biofinity XR",
      "Biofinity XR toric",
      "Proclear XR toric",
      "Proclear XR multifocal",
    ];
    return exclusionList.includes(lensName);
  };

  const renderPowerButtons = (section, lens) => {
    const buttons = [];
    const { pl, maxMinus, maxPlus, minusQuarter, plusQuarter } = lens;

    const isPowerIncluded = (power) => {
      return possibleSphValues.includes(power);
    };

    switch (section) {
      case "lowMinus":
        let lowMinusText = "";
        if (pl && maxMinus <= 6) {
          if (minusQuarter) {
            lowMinusText = "pl to -6.00";
          } else {
            lowMinusText = "pl, -0.50 to -6.00";
          }
        } else if (minusQuarter && maxMinus <= 6) {
          lowMinusText = "-0.25 to -6.00";
        } else if (maxMinus <= 6) {
          lowMinusText = "-0.50 to -6.00";
        }
        return (
          <>
            <span key={lowMinusText} className={`power-text`}>
              {lowMinusText}
            </span>
            <span
              className="stepText"
              style={{ fontSize: "9px", paddingTop: "2px", paddingLeft: "5px" }}
            >
              (in 0.25 steps)
            </span>
          </>
        );

      case "highMinus":
        switch (lens.name) {
          case "Biofinity XR":
            for (let i = -12.5; i >= lens.maxMinus; i -= 0.25) {
              const power = i.toFixed(2);
              const isActive = isPowerIncluded(power) && i <= maxPlus;
              const displayPower = `+${power}`;

              buttons.push(
                <button
                  key={power}
                  className={`power-button ${isActive ? "active" : "inactive"}`}
                  disabled={!isActive}
                  style={{
                    display:
                      isActive || Math.abs(i) <= 10 ? "inline-block" : "none",
                  }}
                >
                  {displayPower}
                </button>
              );
            }
            break;
          default:
            if (lens.name === "Biofinity XR toric") {
              return (
                <>
                  <div className="xrText">
                    <span style={{ fontWeight: "bold" }}>
                      Cyl -0.75 to -2.25:
                    </span>{" "}
                    -10.50 to -20.00{" "}
                    <span className="stepText">(0.50D steps)</span>
                  </div>
                  <div className="xrText">
                    <span style={{ fontWeight: "bold" }}>
                      Cyl -2.75 to -5.75:
                    </span>{" "}
                    &nbsp;pl to -6.00{" "}
                    <span className="stepText">(0.25D steps)</span>; -6.50 to
                    -20.00 <span className="stepText">(0.50D steps)</span>
                  </div>
                </>
              );
            } else if (lens.name === "Proclear XR toric") {
              return (
                <>
                  <div className="xrText">
                    <span style={{ fontWeight: "bold" }}>
                      Cyl -0.75 to -2.25:
                    </span>{" "}
                    -8.50 to -10.00{" "}
                    <span className="stepText">(0.50D steps)</span>
                  </div>
                  <div className="xrText">
                    <span style={{ fontWeight: "bold" }}>
                      Cyl -2.75 to -5.75:
                    </span>{" "}
                    &nbsp;pl to -6.25{" "}
                    <span className="stepText">(0.25D steps)</span>; -6.50 to
                    -10.00 <span className="stepText">(0.50D steps)</span>
                  </div>
                </>
              );
            } else if (lens.name === "Proclear XR multifocal") {
              return (
                <>
                  <div className="xrText">
                    <span style={{ fontWeight: "bold" }}>
                      Add +1.00 to +4.00, BC 8.4:
                    </span>{" "}
                    &nbsp;pl to -6.25{" "}
                    <span className="stepText">(0.25D steps)</span>; -6.50 to
                    -20.00 <span className="stepText">(0.50D steps)</span>
                  </div>
                  <div className="xrText">
                    <span style={{ fontWeight: "bold" }}>
                      Add +1.00 to +2.50, BC 8.7:
                    </span>{" "}
                    -8.50 to -20.00{" "}
                    <span className="stepText">(0.50D steps)</span>
                  </div>
                  <div className="xrText">
                    <span style={{ fontWeight: "bold" }}>
                      Add +3.00 to +4.00, BC 8.7:
                    </span>{" "}
                    &nbsp;pl to -6.25{" "}
                    <span className="stepText">(0.25D steps)</span>; -6.50 to
                    -20.00 <span className="stepText">(0.50D steps)</span>
                  </div>
                </>
              );
            } else {
              for (let i = -6.25; i >= lens.maxMinus; i -= 0.25) {
                const power = i.toFixed(2);
                const isActive = isPowerIncluded(power);

                if (isActive) {
                  buttons.push(
                    <button
                      key={power}
                      className={`power-button ${
                        isActive ? "active" : "inactive"
                      }`}
                      disabled={!isActive}
                      style={{
                        display: "inline-block", // Always display the button
                      }}
                    >
                      {power}
                    </button>
                  );
                }
              }
            }
            break;
        }
        break;

      case "plus":
        switch (lens.name) {
          case "Biofinity XR toric":
            return (
              <>
                <div className="xrText">
                  <span style={{ fontWeight: "bold" }}>
                    Cyl -0.75 to -2.25:
                  </span>{" "}
                  +8.50 to +20.00{" "}
                  <span className="stepText">(0.50D steps)</span>
                </div>
                <div className="xrText">
                  <span style={{ fontWeight: "bold" }}>
                    Cyl -2.75 to -5.75:
                  </span>{" "}
                  +0.25 to +6.00 <span className="stepText">(0.25D steps)</span>
                  ; +6.50 to +20.00{" "}
                  <span className="stepText">(0.50D steps)</span>
                </div>
              </>
            );
          case "Proclear XR toric":
            return (
              <>
                <div className="xrText">
                  <span style={{ fontWeight: "bold" }}>
                    Cyl -0.75 to -2.25:
                  </span>{" "}
                  +6.25 to +10.00{" "}
                  <span className="stepText">(0.50D steps after +6.50)</span>
                </div>
                <div className="xrText">
                  <span style={{ fontWeight: "bold" }}>
                    Cyl -2.75 to -5.75:
                  </span>{" "}
                  +0.25 to +6.25 <span className="stepText">(0.25D steps)</span>
                  ; +6.50 to +10.00{" "}
                  <span className="stepText">(0.50D steps)</span>
                </div>
              </>
            );
          case "Proclear XR multifocal":
            return (
              <>
                <div className="xrText">
                  <span style={{ fontWeight: "bold" }}>
                    Add +1.00 to +4.00, BC 8.4:
                  </span>{" "}
                  +0.25 to +6.25 <span className="stepText">(0.25D steps)</span>
                  ; +6.50 to +20.00{" "}
                  <span className="stepText">(0.50D steps)</span>
                </div>
                <div className="xrText">
                  <span style={{ fontWeight: "bold" }}>
                    Add +1.00 to +2.50, BC 8.7:
                  </span>{" "}
                  +6.25 to +20.00
                  <span className="stepText">(0.50D steps after +6.50)</span>
                </div>
                <div className="xrText">
                  <span style={{ fontWeight: "bold" }}>
                    Add +3.00 to +4.00, BC 8.7:
                  </span>{" "}
                  +0.25 to +6.25 <span className="stepText">(0.25D steps)</span>
                  ; +6.50 to +20.00{" "}
                  <span className="stepText">(0.50D steps)</span>
                </div>
              </>
            );

          case "Biofinity XR":
            for (let i = 8.5; i <= maxPlus; i += 0.25) {
              const power = i.toFixed(2);
              const isActive = isPowerIncluded(power) && i <= maxPlus;
              const displayPower = `+${power}`;

              buttons.push(
                <button
                  key={power}
                  className={`power-button ${isActive ? "active" : "inactive"}`}
                  disabled={!isActive}
                  style={{
                    display:
                      isActive || Math.abs(i) <= 10 ? "inline-block" : "none",
                  }}
                >
                  {displayPower}
                </button>
              );
            }
            break;
          default:
            for (let i = 0.25; i <= maxPlus; i += 0.25) {
              const power = i.toFixed(2);
              const isActive =
                isPowerIncluded(power) &&
                ((i === 0.25 && plusQuarter) || (i >= 0.5 && i <= maxPlus));

              if (isActive) {
                buttons.push(
                  <button
                    key={power}
                    className={`power-button ${
                      isActive ? "active" : "inactive"
                    }`}
                    disabled={!isActive}
                    style={{
                      display: "inline-block", // Always display the button
                    }}
                  >
                    {`+${power}`}
                  </button>
                );
              }

              // buttons.push(
              //   <button
              //     key={power}
              //     className={`power-button ${isActive ? "active" : "inactive"}`}
              //     disabled={!isActive}
              //     style={{
              //       display:
              //         isActive || Math.abs(i) <= 10 ? "inline-block" : "none",
              //     }}
              //   >
              //     {`+${power}`}
              //   </button>
              // );
            }
            break;
        }
        break;

      default:
        break;
    }

    // Render only active buttons
    const activeButtons = buttons.filter((button) =>
      button.props.className.includes("active")
    );

    return activeButtons;
  };

  return (
    <div className="popupSphere">
      <div className="power-buttons-container">
        {!isInExclusionList(lens.name) && (
          <div className="power-section">
            <h5>Low minus:</h5>
            {renderPowerButtons("lowMinus", lens)}
          </div>
        )}
        <hr
          style={{ display: !isInExclusionList(lens.name) ? "block" : "none" }}
        />
        {lens.name === "Biofinity XR toric" ||
        lens.name === "Proclear XR toric" ||
        lens.name === "Proclear XR multifocal" ? (
          <>
            <h5 className="minusText">Minus:</h5>
            {renderPowerButtons("highMinus", lens)}
          </>
        ) : (
          <div className="power-section">
            <h5>High minus:</h5>
            {renderPowerButtons("highMinus", lens)}
          </div>
        )}
        <hr />
        <div className="power-section">
          <h5>Plus:</h5>
          {renderPowerButtons("plus", lens)}
        </div>
      </div>
    </div>
  );
}

export default SmallLensSphProperties;
