import React from "react";

function LensAddOptions({ name }) {
  switch (name) {
    case "Moist Multifocal":
    case "Oasys Max 1-Day Multifocal":
    case "Oasys Multifocal":
      return <span className="addSpan">LOW, MID, HIGH</span>;
    case "Air Optix plus HydraGlyde Multifocal":
    case "Dailies AquaComfort Plus Multifocal":
    case "Dailies Total 1 Multifocal":
    case "TOTAL30 Multifocal":
      return <span className="addSpan">LO, MED, HI</span>;
    case "Biotrue ONEday for Presbyopia":
    case "Infuse One-Day Multifocal":
    case "Ultra for Presbyopia":
    case "Ultra Multifocal for Astigmatism":
    case "PureVision Multi-Focal":
    case "PureVision2 For Presbyopia":
    case "SofLens Multi-Focal":
    case "clariti 1 day multifocal":
      return <span className="addSpan">Low, High</span>;
    case "Biofinity multifocal":
    case "Biofinity toric multifocal":
    case "Proclear multifocal":
      return (
        <span className="addSpan">+1.00, +1.50, +2.00, +2.50 ; D / N</span>
      );
    case "MyDay multifocal":
      return <span className="addSpan">Low, Med, High</span>;
    case "Proclear 1 day Multifocal":
      return <span className="addSpan">MF (single power profile)</span>;
    case "Proclear XR multifocal":
    case "Proclear multifocal toric":
      return (
        <span className="addSpan">
          +1.00, +1.50, +2.00, +2.50, +3.00, +3.50, +4.00 ; D / N
        </span>
      );
    // Add more cases for other multifocal lens options
    default:
      return <span>{name} Option</span>;
  }
}

export default LensAddOptions;
