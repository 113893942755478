const privateArray = [
  {
    Private: "AquaSoft Daily",
    Original: "VISCO Soft",
    Store: "1-800 Contacts",
  },
  {
    Private: "Sofmed 55",
    Original: "Biomedics 55 Premier",
    Store: "America's Best",
  },
  {
    Private: "Sofmed breathables 1-Day",
    Original: "clariti 1 day",
    Store: "America's Best",
  },
  {
    Private: "Sofmed breathables 1-Day Toric",
    Original: "clariti 1 day toric",
    Store: "America's Best",
  },
  {
    Private: "Sofmed breathables advanced",
    Original: "Avaira Vitality",
    Store: "America's Best",
  },
  {
    Private: "Sofmed breathables advanced toric",
    Original: "Avaira Vitality toric",
    Store: "America's Best",
  },
  {
    Private: "Sofmed breathables XW",
    Original: "Biofinity",
    Store: "America's Best",
  },
  {
    Private: "Sofmed breathables XW multifocal",
    Original: "Biofinity multifocal",
    Store: "America's Best",
  },
  {
    Private: "Sofmed breathables XW toric",
    Original: "Biofinity toric",
    Store: "America's Best",
  },
  {
    Private: "Sofmed Toric Weeklies",
    Original: "Biomedics toric",
    Store: "America's Best",
  },
  {
    Private: "Kirkland Signature Premium Daily Disposable",
    Original: "MyDay daily disposable",
    Store: "Costco",
  },
  {
    Private: "Natural Eyes HydraWear XW",
    Original: "Biofinity",
    Store: "Eyeglass World",
  },
  {
    Private: "Natural Eyes HydraWear ",
    Original: "Avaira Vitality",
    Store: "Eyeglass World",
  },
  {
    Private: "Natural Eyes HydraWear 1-Day",
    Original: "clariti 1 day",
    Store: "Eyeglass World",
  },
  {
    Private: "Natural Eyes HydraWear FOR ASTIGMATISM",
    Original: "Avaira Vitality Toric",
    Store: "Eyeglass World",
  },
  {
    Private: "Natural Eyes HydraWear XW Toric",
    Original: "Biofinity toric",
    Store: "Eyeglass World",
  },
  {
    Private: "Natural Eyes HydraWear Ultimate 1-Day",
    Original: "MyDay daily disposable",
    Store: "Eyeglass World",
  },
  {
    Private: "Natural Eyes HydraWear Ultimate 1-Day Multifocal",
    Original: "MyDay multifocal",
    Store: "Eyeglass World",
  },
  {
    Private: "Natural Eyes HydraWear Ultimate 1-Day Toric",
    Original: "MyDay toric",
    Store: "Eyeglass World",
  },
  {
    Private: "LensCrafters One Day Premium Daily Disposable",
    Original: "MyDay daily disposable",
    Store: "LensCrafters",
  },
  {
    Private: "LensCrafters One Day Daily Disposable",
    Original: "Biotrue ONEday",
    Store: "LensCrafters",
  },
  {
    Private: "LensCrafters Monthly",
    Original: "Biofinity",
    Store: "LensCrafters",
  },
  {
    Private: "LensCrafters One Day Premium Daily Disposable For Astigmatism",
    Original: "MyDay toric",
    Store: "LensCrafters",
  },
  {
    Private: "LensCrafters Monthly For Astigmatism",
    Original: "Biofinity toric",
    Store: "LensCrafters",
  },
  {
    Private: "LensCrafters One Day Premium For Multifocal",
    Original: "MyDay multifocal",
    Store: "LensCrafters",
  },
  {
    Private: "LensCrafters Monthly Modern Lifestyle",
    Original: "Biofinity Energys",
    Store: "LensCrafters",
  },
  {
    Private: "LensCrafters Monthly Multifocal",
    Original: "Biofinity multifocal",
    Store: "LensCrafters",
  },
  {
    Private: "Ray-Ban 1-DAY",
    Original: "clariti 1 day",
    Store: "LensCrafters, Target Optical",
  },
  {
    Private: "Ray-Ban 1-DAY for Astigmatism",
    Original: "clarti 1 day toric",
    Store: "LensCrafters, Target Optical",
  },
  {
    Private: "Ray-Ban 1-DAY Multifocal",
    Original: "clariti 1 day multifocal",
    Store: "LensCrafters, Target Optical",
  },
  {
    Private: "Pearle Vision 1-Day",
    Original: "clariti 1 day",
    Store: "Pearle Vision",
  },
  {
    Private: "Pearle Vision 1-Day for Astigmatism",
    Original: "clarti 1 day toric",
    Store: "Pearle Vision",
  },
  {
    Private: "Pearle Vision 1-Day Multifocal",
    Original: "clariti 1 day multifocal",
    Store: "Pearle Vision",
  },
  {
    Private: "Pearle Vision 1-Day Premium",
    Original: "MyDay daily disposable",
    Store: "Pearle Vision",
  },
  {
    Private: "Pearle Vision 2-Week",
    Original: "Avaira Vitality",
    Store: "Pearle Vision",
  },
  {
    Private: "Pearle Vision 2-Week for Astigmatism",
    Original: "Avaira Vitality toric",
    Store: "Pearle Vision",
  },
  {
    Private: "Pearle Vision Monthly",
    Original: "Biofinity",
    Store: "Pearle Vision",
  },
  {
    Private: "Pearle Vision Monthly for Astigmatism",
    Original: "Biofinity toric",
    Store: "Pearle Vision",
  },
  {
    Private: "Pearle Vision Monthly Multifocal",
    Original: "Biofinity multifocal",
    Store: "Pearle Vision",
  },
  {
    Private: "Polysoft 55 Asphere",
    Original: "Biomedics 55 Premier",
    Store: "Pearle Vision",
  },
  {
    Private: "Aquatech Plus Premium",
    Original: "Biofinity",
  },
  {
    Private: "Aquatech Plus Premium Toric",
    Original: "Biofinity toric",
  },
  {
    Private: "Aquatech Plus 1-Day Toric",
    Original: "clariti 1 day toric",
  },
  {
    Private: "Aquatech Plus 1-Day Multifocal",
    Original: "clariti 1 day multifocal",
  },
  {
    Private: "Aquatech Plus II",
    Original: "Avaira Vitality",
  },
  {
    Private: "Aquatech Plus II Toric",
    Original: "Avaira Vitality toric",
  },
  {
    Private: "Aquatech Toric",
    Original: "Biomedics toric",
  },
  {
    Private: "Mediflex 55 Aspheric",
    Original: "Biomedics 55 Premier",
  },
  {
    Private: "Mediflex Aquafinity 116 Toric",
    Original: "Biofinity toric",
  },
  {
    Private: "Mediflex Elite Toric",
    Original: "Biofinity toric",
  },
  {
    Private: "Mediflex Elite Multifocal",
    Original: "Biofinity multifocal",
  },
  {
    Private: "Mediflex Hydra 1 Day",
    Original: "Proclear 1 day",
  },
  {
    Private: "Mediflex Supreme 1 Day",
    Original: "MyDay daily disposable",
  },
  {
    Private: "Mediflex Supreme 1 Day Toric",
    Original: "MyDay toric",
  },
  {
    Private: "UltraFlex II Aspheric",
    Original: "Biomedics 55 Premier",
  },
  {
    Private: "UltraFlex Toric",
    Original: "Biomedics toric",
  },
  {
    Private: "Aqualens 55 Aspheric",
    Original: "Biomedics 55 Premier",
  },
  {
    Private: "Aqualite 55 Pro Aspheric",
    Original: "Biomedics 55 Premier",
  },
  {
    Private: "iWear Activ",
    Original: "clariti 1 day",
  },
  {
    Private: "iWear Harmony",
    Original: "MyDay daily disposable",
  },
  {
    Private: "iWear Balance",
    Original: "Avaira Vitality",
  },
  {
    Private: "iWear Oxygen",
    Original: "Biofinity",
  },
  {
    Private: "Aquaclear",
    Original: "Biofinity",
    Store: "Vision Source",
  },
  {
    Private: "Aquaclear Premium",
    Original: "Biofinity Energys",
    Store: "Vision Source",
  },
  {
    Private: "Aquaclear Toric",
    Original: "Biofinity toric",
    Store: "Vision Source",
  },
  {
    Private: "Aquaclear Multifocal",
    Original: "Biofinity multifocal",
    Store: "Vision Source",
  },
  {
    Private: "Aquaclear 110",
    Original: "Avaira Vitality",
    Store: "Vision Source",
  },
  {
    Private: "Aquaclear 110 Toric",
    Original: "Avaira Vitality toric",
    Store: "Vision Source",
  },
  {
    Private: "Fresh Day",
    Original: "clariti 1 day",
    Store: "Vision Source",
  },
  {
    Private: "Fresh Day for Astigmatism",
    Original: "clariti 1 day toric",
    Store: "Vision Source",
  },
  {
    Private: "Fresh Day Multifocal",
    Original: "clariti 1 day multifocal",
    Store: "Vision Source",
  },
  {
    Private: "Reveal",
    Original: "MyDay daily disposable",
    Store: "Vision Source",
  },
  {
    Private: "Reveal Toric",
    Original: "MyDay toric",
    Store: "Vision Source",
  },
  {
    Private: "Voyant 1 Day",
    Original: "clariti 1 day",
    Store: "Vision Source",
  },
  {
    Private: "Voyant 1 Day Toric",
    Original: "clariti 1 day toric",
    Store: "Vision Source",
  },
  {
    Private: "Voyant 1 Day Multifocal",
    Original: "clariti 1 day multifocal",
    Store: "Vision Source",
  },
  {
    Private: "Voyant 1 Day Premium",
    Original: "MyDay daily disposable",
    Store: "Vision Source",
  },
  {
    Private: "Voyant 1 Day Premium Toric",
    Original: "MyDay toric",
    Store: "Vision Source",
  },
  {
    Private: "Ethos AquaTech 1 Day",
    Original: "clariti 1 day",
    Store: "Visionworks",
  },
  {
    Private: "Ethos AquaTech 1 Day for Astigmatism",
    Original: "clariti 1 day toric",
    Store: "Visionworks",
  },
  {
    Private: "Ethos AquaTech 1 Day Multifocal",
    Original: "clariti 1 day multifocal",
    Store: "Visionworks",
  },
  {
    Private: "Ethos AquaTech 2 Week",
    Original: "Avaira Vitality",
    Store: "Visionworks",
  },
  {
    Private: "Ethos AquaTech 2 Week for Astigmatism",
    Original: "Avaira Vitality toric",
    Store: "Visionworks",
  },
  {
    Private: "Ethos AquaTech Monthly",
    Original: "Biofinity",
    Store: "Visionworks",
  },
  {
    Private: "Ethos AquaTech Monthly for Astigmatism",
    Original: "Biofinity toric",
    Store: "Visionworks",
  },
  {
    Private: "Ethos AquaTech Monthly Multifocal",
    Original: "Biofinity multifocal",
    Store: "Visionworks",
  },
  {
    Private: "eureka!",
    Original: "Biotrue ONEday",
    Store: "Walmart",
  },
  {
    Private: "eureka! Monthly",
    Original: "Ultra",
    Store: "Walmart",
  },
  {
    Private: "Scout",
    Original: "Miru 1day Flat Pack",
    Store: "Warby Parker",
  },
];

export default privateArray;
