import React from "react";

const AxisDisplayMenicon = ({ lensName, cyl }) => {
  let additionalText = "";

  const fullRange = "Full range in 10° steps";
  const minimumMeniconRange = "015, 090, 165, 180";

  switch (lensName) {
    case "Miru 1month toric":
      additionalText = (
        <>
          <div className="cylSphDiv">
            <span className="cylSpan">Sph: all powers (-13.00 to pl)</span>
          </div>
          <div className="cylRange1">
            <span className="cylDisplaySpan">{fullRange}</span>
          </div>
        </>
      );
      break;
    case "Miru 1day Flat Pack Toric":
      if (cyl >= -1.25) {
        additionalText = (
          <div>
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: -8.00 to +2.00</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{minimumMeniconRange}</span>
            </div>
          </div>
        );
      } else if (cyl === -1.75) {
        additionalText = (
          <div>
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: -8.00 to +2.00</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">180</span>
            </div>
          </div>
        );
      }
      break;

    default:
      additionalText = "Additional details.";
      break;
  }

  return <div>{additionalText}</div>;
};

export default AxisDisplayMenicon;
