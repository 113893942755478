import React from "react";

const LensCylButtons = ({ lens, handleCylClick }) => {
  const renderCylButtons = () => {
    const buttons = [];
    const maxCyl = lens.maxCyl;

    const createButtonClickHandler = (cylValue) => {
      return () => handleCylClick(cylValue);
    };

    let cyl;
    for (cyl = -0.75; cyl >= maxCyl && cyl > -2.75; cyl -= 0.5) {
      buttons.push(
        <button
          key={cyl}
          className="cyl-button"
          onClick={createButtonClickHandler(cyl)}
        >
          {cyl}
        </button>
      );
    }

    if (maxCyl === -2.75) {
      buttons.push(
        <button
          key={maxCyl}
          className="cyl-button"
          onClick={createButtonClickHandler(maxCyl)}
        >
          -2.75
        </button>
      );
    }

    if (maxCyl < -2.75) {
      buttons.push(
        <button
          key="XR"
          className="cyl-button"
          onClick={createButtonClickHandler(-2.75)}
        >
          -2.75 to {lens.maxCyl}
        </button>
      );
    }

    return buttons;
  };

  return <div className="cyl-buttons-container">{renderCylButtons()}</div>;
};

export default LensCylButtons;
