import React from "react";

const AxisDisplayAlcon = ({ lensName, cyl }) => {
  let additionalText = "";

  const fullRange = "Full range in 10° steps";
  const reducedAlconRange = "010, 020, 070, 080, 090, 100, 110, 160, 170, 180";
  const minimumAlconRange = "010, 020, 160, 170, 180";

  switch (lensName) {
    case "Air Optix plus HydraGlyde for Astigmatism":
      additionalText = (
        <>
          <div className="cylSphDiv">
            <span className="cylSpan">Sph: all powers (-10.00 to +6.00)</span>
          </div>
          <div className="cylRange1">
            <span className="cylDisplaySpan">{fullRange}</span>
          </div>
        </>
      );
      break;
    case "TOTAL30 for Astigmatism":
      additionalText = (
        <>
          <div className="cylSphDiv">
            <span className="cylSpan">Sph: all powers (-10.00 to +8.00)</span>
          </div>
          <div className="cylRange1">
            <span className="cylDisplaySpan">{fullRange}</span>
          </div>
        </>
      );
      break;
    case "Dailies AquaComfort Plus Toric":
      additionalText = (
        <>
          <div className="cylSphDiv">
            <span className="cylSpan">Sph: all powers (-8.00 to +4.00)</span>
          </div>
          <div className="cylRange1">
            <span className="cylDisplaySpan">{reducedAlconRange}</span>
          </div>
        </>
      );
      break;
    case "Dailies Total 1 for Astigmatism":
    case "Precision1 for Astigmatism":
      if (cyl >= -1.75) {
        additionalText = (
          <div>
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: pl to -6.00</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{fullRange}</span>
            </div>
            {/* //// */}
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: -6.50 to -8.00:</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{reducedAlconRange}</span>
            </div>
            {/* //// */}
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: +0.50 to +4.00:</span>
            </div>
            <div className="cylRange2">
              <span className="cylDisplaySpan">{reducedAlconRange}</span>
            </div>
          </div>
        );
      } else if (cyl === -2.25) {
        additionalText = (
          <div>
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: pl to -6.00</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{reducedAlconRange}</span>
            </div>
            {/* //// */}
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: -6.50 to -8.00:</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{minimumAlconRange}</span>
            </div>
            {/* //// */}
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: +0.50 to +4.00:</span>
            </div>
            <div className="cylRange2">
              <span className="cylDisplaySpan">{minimumAlconRange}</span>
            </div>
          </div>
        );
      }
      break;

    default:
      additionalText = "Additional details.";
      break;
  }

  return <div>{additionalText}</div>;
};

export default AxisDisplayAlcon;
