function calculatePossibleSphValues({
  pl,
  cyl,
  minusQuarter,
  plusQuarter,
  maxMinus,
  maxPlus,
  fiftySteps,
  name,
}) {
  let possibleSphValues = [];

  // Add 0.00, -0.25, and +0.25
  pl && possibleSphValues.push("0.00");
  minusQuarter && possibleSphValues.push("-0.25");
  plusQuarter && possibleSphValues.push("0.25");

  // Loop for negative powers
  for (let power = -0.5; power >= maxMinus; power -= 0.25) {
    possibleSphValues.push(power.toFixed(2));
    if (power <= -6 && fiftySteps) {
      // Change step size after -6.00 is pushed
      power -= 0.25;
    }
  }

  // Loop for positive powers
  for (let power = 0.5; power <= maxPlus; power += 0.25) {
    possibleSphValues.push(power.toFixed(2));
    if (power >= 6 && fiftySteps) {
      // Change step size after 6.00 is pushed
      power += 0.25;
    }
  }

  // Handle special cases
  switch (name) {
    case "Define":
      possibleSphValues = possibleSphValues.filter((value) => value !== "0.75");
      break;

    case "Proclear multifocal":
    case "Proclear toric":
      const power1 = -6.25;
      possibleSphValues.push(power1.toFixed(2));
      break;

    case "SofLens Daily Disposable":
    case "Proclear multifocal toric":
      const power2 = -6.25;
      const power3 = 6.25;
      possibleSphValues.push(power2.toFixed(2), power3.toFixed(2));
      break;

    case "Biomedics 55 Premier":
    case "Biomedics toric":
    case "MyDay daily disposable":
    case "MyDay Energys":
    case "Proclear 1 day":
      possibleSphValues = possibleSphValues.filter(
        (value) => value !== "5.25" && value !== "5.75"
      );
      break;

    case "MyDay multifocal":
      possibleSphValues = possibleSphValues.filter(
        (value) =>
          value !== "-10.25" &&
          value !== "-10.75" &&
          value !== "-11.25" &&
          value !== "-11.75"
      );
      break;

    case "Air Optix plus HydraGlyde":
    case "TOTAL30":
      const power4 = -6.25;
      const power5 = -6.75;
      const power6 = -7.25;
      const power7 = -7.25;
      possibleSphValues.push(
        power4.toFixed(2),
        power5.toFixed(2),
        power6.toFixed(2),
        power7.toFixed(2)
      );
      break;

    case "Freshlook Colors":
    case "Freshlook Colorblends":
    case "Freshlook Handling Tint":
      possibleSphValues = possibleSphValues.filter(
        (value) =>
          value !== "2.25" &&
          value !== "2.75" &&
          value !== "3.25" &&
          value !== "3.75" &&
          value !== "4.25" &&
          value !== "4.75" &&
          value !== "5.25" &&
          value !== "5.75"
      );
      break;

    case "Biofinity XR":
      // Filter out values between 8 and -12
      possibleSphValues = possibleSphValues.filter((value) => {
        const floatValue = parseFloat(value);
        return floatValue > 8 || floatValue < -12;
      });
      break;

    case "Biofinity XR toric":
      possibleSphValues = possibleSphValues.filter((value) => {
        const floatValue = parseFloat(value);
        return cyl <= -2.75 || floatValue <= -10.5 || floatValue >= 8;
      });
      break;

    case "Proclear XR toric":
      possibleSphValues = possibleSphValues.filter((value) => {
        const floatValue = parseFloat(value);
        return cyl <= -2.75 || floatValue <= -8.5 || floatValue >= 6.25;
      });
      break;

    // Add or remove specific values for LensB
    // if (specialCases.includes("LensB")) {
    //   possibleSphValues.push("2.00"); // Add a specific value
    //   possibleSphValues = possibleSphValues.filter(
    //     (value) => value !== "-1.00" // Remove a specific value
    //   );
    // }
    // Add more cases for other lenses as needed
    default:
      break;
  }

  return possibleSphValues;
}

export default calculatePossibleSphValues;
