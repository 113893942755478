import React, { useState } from "react";

function SidebarSection({ title, children, defaultCollapsed = false }) {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={`sidebar-section ${collapsed ? "collapsed" : ""}`}>
      <div
        className={"section-header"}
        onClick={toggleCollapse}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 className="sidebar-section-label">{title}</h4>
        <span className="toggle-icon">{collapsed ? "▼" : "▲"}</span>
      </div>
      <div
        className="section-content"
        style={{
          maxHeight: collapsed ? "0" : "1150px",
          transition: "max-height 0.3s ease-in-out",
        }}
      >
        {React.Children.toArray(children).map((child, index) => (
          <div key={index} className="checkbox-option">
            {child}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SidebarSection;
