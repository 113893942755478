import React from "react";

function CylDropdown({ filters, handleFilterChange, lenses }) {
  // Filter out lenses without maxCyl property
  const validLenses = lenses.filter((lens) => lens.maxCyl !== undefined);

  const maxCylValues = validLenses.map((lens) => lens.maxCyl);
  const minCyl = Math.min(...maxCylValues, -0.75);

  const cylOptions = [];
  let currentCyl = -0.75;

  while (currentCyl >= minCyl) {
    cylOptions.push(currentCyl.toFixed(2));
    currentCyl -= 0.5;
  }

  return (
    <div className="input-container-cyl">
      <h4>CYL</h4>
      <select
        name="cyl"
        value={filters.cyl} // Set value to the selected CYL value from filters
        onChange={handleFilterChange} // Call handleFilterChange when selection changes
      >
        <option value="">---</option>
        {cylOptions.map((cyl, index) => (
          <option key={index} value={cyl}>
            {cyl}
          </option>
        ))}
      </select>
    </div>
  );
}

export default CylDropdown;
