const LensByAxisVistakon = ({ power, axis, cyl }) => {
  if (axis === "---" || axis === "") {
    return []; // Return all lenses if axis is set to "---" or empty string
  }

  const initialLensArray = [
    "Oasys for Astigmatism",
    "Oasys 1-Day for Astigmatism",
    "Moist for Astigmatism",
    "Vita for Astigmatism",
  ];

  power = parseFloat(power);
  cyl = parseFloat(cyl);

  const minimumAxis = ["010", "020", "160", "170", "180"];
  const reducedAxis = [...minimumAxis, "070", "080", "090", "100", "110"];

  let powerRange = "";

  if (!power || (power >= -6.0 && power <= 0.0)) {
    powerRange = "lowMinus";
  } else if (power < 6.0) {
    powerRange = "highMinus";
  } else {
    powerRange = "plus";
  }

  let filteredLensNames = [];

  switch (powerRange) {
    case "lowMinus":
      if (!cyl || cyl >= -1.75) {
        filteredLensNames = initialLensArray;
      } else if (reducedAxis.includes(axis)) {
        filteredLensNames = initialLensArray;
      }
      break;

    case "highMinus":
      if (!cyl || cyl >= -1.25) {
        if (!axis || reducedAxis.includes(axis)) {
          filteredLensNames = initialLensArray;
        } else {
          filteredLensNames = initialLensArray.filter(
            (lens) => lens !== "Oasys for Astigmatism"
          );
        }
      } else if (cyl === -1.75) {
        if (!axis || minimumAxis.includes(axis)) {
          filteredLensNames = initialLensArray;
        } else if (reducedAxis.includes(axis)) {
          filteredLensNames = initialLensArray.filter(
            (lens) => lens !== "Oasys for Astigmatism"
          );
        }
      } else if (cyl <= -2.25 && (!axis || minimumAxis.includes(axis))) {
        filteredLensNames = ["Oasys for Astigmatism"];
      }
      break;

    case "plus":
      if ((!cyl || cyl >= -1.75) && (!axis || reducedAxis.includes(axis))) {
        filteredLensNames = initialLensArray;
      }
      break;

    default:
      filteredLensNames = initialLensArray; // Return initialLensArray by default
      break;
  }

  return filteredLensNames;
};

export default LensByAxisVistakon;
