function calculatePossibleCylValues(lens) {
  let possibleCylValues = [];

  for (let cyl = -0.75; cyl >= lens.maxCyl; cyl -= 0.5) {
    possibleCylValues.push(cyl.toFixed(2));
  }
  return possibleCylValues;
}

export default calculatePossibleCylValues;
