import React, { useRef, useEffect, useState } from "react";
import LensSphProperties from "./LensSphProperties";
import SmallLensSphProperties from "./SmallLensSphProperties";
import LensCylButtons from "./LensCylButtons";
import LensAddOptions from "./LensAddOptions";
import CylDisplay from "./CylDisplay";

import AdditionalPopup from "./AdditionalPopup";

const scheduleMap = {
  DD: "Daily Disposable",
  "2W": "Two Weeks",
  "1M": "Monthly",
};

export default function PopUpCard({
  lens,
  darkMode,
  examMode,
  onClose,
  isSmallScreen,
  isXsmallScreen,
}) {
  const popupRef = useRef(null);
  const [selectedCyl, setSelectedCyl] = useState(null);
  const [showCylDisplay, setShowCylDisplay] = useState(false);
  // const [showAdditionalPopup, setShowAdditionalPopup] = useState(false);
  const [shouldRenderPopup, setShouldRenderPopup] = useState(false);

  const topPosition = isXsmallScreen ? "10%" : "20%";

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    function handleResize() {
      const screenHeight = window.innerHeight;
      if (screenHeight >= 695) {
        setShouldRenderPopup(true);
      } else {
        setShouldRenderPopup(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleCylClick = (cyl) => {
    setSelectedCyl(cyl);
    setShowCylDisplay(true);
  };

  const handleCylDisplayClose = () => {
    setShowCylDisplay(false);
    setSelectedCyl(null);
  };

  return (
    <div className={`popup ${darkMode ? "dark-mode" : ""}`} ref={popupRef}>
      <div className="popup-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h4>{lens && lens.name}</h4>
        <div className="modal-body">
          <div>
            <span className="label">
              {(isSmallScreen || isXsmallScreen) &&
              lens.BC &&
              lens.BC.split(",").length > 2
                ? "SPH"
                : "Sphere"}
            </span>

            {(isSmallScreen || isXsmallScreen) && (
              <div className="small-info">
                <span id="bc-spacer">|</span>
                <span className="small-label" id="bc-label">
                  BC:
                </span>
                <span className="small-value">{lens.BC}</span>
                <span id="bc-spacer">|</span>
                <span className="small-label" id="diam-label">
                  Diam:
                </span>
                <span className="small-value">{lens.diam}</span>
              </div>
            )}
          </div>
          {isSmallScreen || isXsmallScreen ? (
            <SmallLensSphProperties lens={lens} />
          ) : (
            <LensSphProperties lens={lens} />
          )}
          {lens && lens.toric && (
            <div>
              <div className="property cylProperty">
                <span className="label cylPopUpLabel">Cyl:</span>
                <LensCylButtons lens={lens} handleCylClick={handleCylClick} />
              </div>
              <hr className="cylhr" />
            </div>
          )}
          {lens && lens.multifocal && (
            <div>
              <div className="property">
                <span className="label">Adds:</span>
                <LensAddOptions name={lens.name} />
              </div>
              <hr />
            </div>
          )}
          <div className="property">
            <span className="label">Replacement Schedule:</span>
            <span className="value">
              {isSmallScreen || isXsmallScreen
                ? lens.schedule
                : scheduleMap[lens.schedule]}
            </span>
          </div>
          {!isSmallScreen && !isXsmallScreen && (
            <div>
              <div>
                <div className="property">
                  <span className="label">Base Curve:</span>
                  <span className="value">{lens && lens.BC}</span>
                </div>
              </div>

              <div className="property">
                <span className="label">Diameter:</span>
                <span className="value">{lens && lens.diam}</span>
                <span></span>
              </div>
              {lens.notice && (
                <div className="notice-container">
                  <span className="notice-value">{lens.notice}</span>
                </div>
              )}
            </div>
          )}
          {shouldRenderPopup && (
            <AdditionalPopup lens={lens} topPosition={topPosition} />
          )}
        </div>
      </div>
      {showCylDisplay && (
        <div className="popup cyl-display-popup">
          <div className="popup-content">
            <CylDisplay
              lensName={lens && lens.name}
              manuf={lens && lens.manuf}
              cyl={selectedCyl}
              onClose={handleCylDisplayClose}
            />
          </div>
        </div>
      )}
    </div>
  );
}
