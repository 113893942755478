import React from "react";
import AxisDisplayAlcon from "./Calculators/AxisCalc/AxisDisplayAlcon";
import AxisDisplayBausch from "./Calculators/AxisCalc/AxisDisplayBausch";
import AxisDisplayCooper from "./Calculators/AxisCalc/AxisDisplayCooper";
import AxisDisplayVistakon from "./Calculators/AxisCalc/AxisDisplayVistakon";
import AxisDisplayMenicon from "./Calculators/AxisCalc/AxisDisplayMenicon";

const AxisDisplay = ({ lensName, manuf, cyl }) => {
  switch (manuf) {
    case "Vistakon":
      return <AxisDisplayVistakon lensName={lensName} cyl={cyl} />;
    case "Alcon":
      return <AxisDisplayAlcon lensName={lensName} cyl={cyl} />;
    case "Bausch + Lomb":
      return <AxisDisplayBausch lensName={lensName} cyl={cyl} />;
    case "CooperVision":
      return <AxisDisplayCooper lensName={lensName} cyl={cyl} />;
    case "Menicon":
      return <AxisDisplayMenicon lensName={lensName} cyl={cyl} />;
    default:
      return <div>Manufacturer not supported.</div>;
  }
};

export default AxisDisplay;
