import React from "react";

export default function Header({ opacity, collapsed }) {
  let marginLeft = "0";
  let adjustedOpacity = opacity;

  if (window.innerWidth > 768) {
    marginLeft = "280px";
  } else if (collapsed) {
    marginLeft = "203px";
    // Ensure opacity is always 1 when sidebar is collapsed on small screens
  } else {
    marginLeft = "3px";
    adjustedOpacity = 1;
  }

  const headerStyle = {
    opacity: adjustedOpacity,
    marginLeft: marginLeft,
    marginTop: "-20px",
  };

  return (
    <div className="header" style={headerStyle}>
      <h3>
        lensbase
        <span>.</span>
        vision
      </h3>
    </div>
  );
}
