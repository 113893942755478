import React, { useState, useEffect } from "react";

function Footer() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 640);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <footer className="footer">
      {/* Conditionally render the first footer column */}
      {isSmallScreen ? (
        <div className="empty-div"></div>
      ) : (
        <div className="footer-column">
          <p>
            <a
              href="http://www.lensbase.vision"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              lensbase.vision
            </a>
          </p>
          <p>
            <a
              href="http://www.lensbase.vision"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              odjobs.vision
            </a>
          </p>
          <p>
            <a
              href="http://www.lensbase.vision"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              eyelife.vision
            </a>
          </p>
        </div>
      )}

      <div className="footer-column">
        <p style={{ fontWeight: "bold", fontSize: "1.8em" }}>
          <a
            href="http://www.lensbase.vision"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            optometry.vision
          </a>
        </p>
        <p style={{ fontSize: "0.6em", marginTop: "5px", cursor: "default" }}>
          © copyright 2024 Passport Informatics
        </p>
      </div>

      {/* Conditionally render the last footer column */}
      {isSmallScreen ? (
        <div className="empty-div"></div>
      ) : (
        <div className="footer-column">
          <p>
            <a
              href="http://www.lensbase.vision"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              sureappoint.com
            </a>
          </p>
          <p>
            <a
              href="http://www.lensbase.vision"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              portascrip.com
            </a>
          </p>
          <p>
            <a
              href="http://www.lensbase.vision"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              lensmatrix.vision
            </a>
          </p>
        </div>
      )}
    </footer>
  );
}

export default Footer;
