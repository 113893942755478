import React from "react";

const AxisDisplayVistakon = ({ lensName, cyl }) => {
  let additionalText = "";

  const fullRange = "Full range in 10° steps";
  const reducedVistakonRange =
    "010, 020, 070, 080, 090, 100, 110, 160, 170, 180";
  const minimumVistakonRange = "010, 020, 160, 170, 180";

  switch (lensName) {
    case "Oasys for Astigmatism":
      if (cyl >= -1.25) {
        additionalText = (
          <div>
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: pl to -9.00</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{fullRange}</span>
            </div>
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: +0.25 to +6.00:</span>
            </div>
            <div className="cylRange2">
              <span className="cylDisplaySpan">{reducedVistakonRange}</span>
            </div>
          </div>
        );
      } else if (cyl === -1.75) {
        additionalText = (
          <div>
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: pl to -6.00</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{fullRange}</span>
            </div>
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: -6.50 to -9.00:</span>
            </div>
            <div className="cylRange2">
              <span className="cylDisplaySpan">{minimumVistakonRange}</span>
            </div>
          </div>
        );
      } else if (cyl <= -2.25) {
        additionalText = (
          <div>
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: pl to -6.00</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{reducedVistakonRange}</span>
            </div>
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: -6.50 to -9.00:</span>
            </div>
            <div className="cylRange2">
              <span className="cylDisplaySpan">{minimumVistakonRange}</span>
            </div>
          </div>
        );
      }
      break;
    case "Oasys 1-Day for Astigmatism":
    case "Moist for Astigmatism":
    case "Vita for Astigmatism":
      if (cyl >= -1.75) {
        additionalText = (
          <div>
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: pl to -6.00</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{fullRange}</span>
            </div>
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: -6.50 to -9.00:</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{reducedVistakonRange}</span>
            </div>
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: +0.25 to +4.00:</span>
            </div>
            <div className="cylRange2">
              <span className="cylDisplaySpan">{reducedVistakonRange}</span>
            </div>
          </div>
        );
      } else if (cyl === -2.25) {
        additionalText = (
          <div>
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: pl to -6.00</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{reducedVistakonRange}</span>
            </div>
          </div>
        );
      }
      break;
    default:
      additionalText = "Additional details.";
      break;
  }

  return <div>{additionalText}</div>;
};

export default AxisDisplayVistakon;
