import React, { useEffect, useState } from "react";
import ImageWithOverlay from "./ImageWithOverlay";
import image1L from "./assets/images/ad_tall.png";
import image1S from "./assets/images/ad_tall_s.png";
import image2 from "./assets/images/ad_wide.png";

function AdBar({ isSmallScreen, isXsmallScreen, isMedScreen, scaleFactor }) {
  const [shouldRenderDiv, setShouldRenderDiv] = useState(false);
  const topPosition = isMedScreen
    ? "calc(22px + 10vh + 373px)"
    : "calc(22px + 10vh + 463px)";

  useEffect(() => {
    const screenHeight = window.innerHeight;
    // console.log("Screen Height:", screenHeight);
    if (screenHeight >= 780) {
      // console.log("Rendering Div");
      setShouldRenderDiv(true);
    } else {
      // console.log("Not Rendering Div");
      setShouldRenderDiv(false);
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      const screenHeight = window.innerHeight;
      if (screenHeight >= 780) {
        setShouldRenderDiv(true);
      } else {
        setShouldRenderDiv(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      {isSmallScreen ? (
        !isXsmallScreen && (
          <>
            <div
              className="ad-container"
              style={{
                top: "calc(-32px + 10vh)",
                width: isSmallScreen ? "50%" : "100%",
              }}
            >
              {/* 150 x 267 */}
              <ImageWithOverlay
                src={image1S}
                alt="Vistakon ad"
                scaleFactor={scaleFactor}
                transformOrigin="bottom right"
              />
            </div>
            <div
              className="ad-container"
              style={{ top: "calc(-32px + 10vh + 287px)" }}
            >
              {/* 150 x 267 */}
              <ImageWithOverlay
                src={image1S}
                alt="Vistakon ad"
                scaleFactor={scaleFactor}
                transformOrigin="top right"
              />
            </div>
          </>
        )
      ) : (
        <>
          <div className="ad-container" style={{ top: "calc(22px + 10vh)" }}>
            {/* 250 x 444 */}
            <ImageWithOverlay
              src={image1L}
              alt="Vistakon ad"
              scaleFactor={scaleFactor}
              transformOrigin="top right"
            />
          </div>
          <div>
            {shouldRenderDiv && (
              <div className="ad-container" style={{ top: topPosition }}>
                {/* 250 x 131 */}
                <ImageWithOverlay
                  src={image2}
                  alt="Vistakon ad"
                  scaleFactor={scaleFactor}
                  transformOrigin="top right"
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default AdBar;
