// ImageWithOverlay.js
function ImageWithOverlay({ src, alt, scaleFactor, transformOrigin }) {
  return (
    <div className="ad-container">
      {/* Apply inline styling to scale the image */}
      <img
        className="image"
        src={src}
        alt={alt}
        style={{
          transform: `scale(${scaleFactor})`,
          marginLeft: "auto", // Position the image to the right
          transformOrigin: transformOrigin, // Use the passed transform origin
        }}
      />
      <div
        className="overlay"
        style={{
          transform: `scale(${scaleFactor})`,
          marginLeft: "auto",
          transformOrigin: transformOrigin,
        }}
      ></div>
    </div>
  );
}

export default ImageWithOverlay;
