const LensByAxisCooper = ({ power, axis, cyl }) => {
  if (axis === "---") {
    return []; // Return an empty array if axis is set to "---"
  }

  const initialLensArray = [
    "Avaira Vitality Toric",
    "Biofinity toric",
    "Biofinity XR toric",
    "Biofinity toric multifocal",
    "Biomedics toric",
    "clariti 1 day toric",
    "MyDay toric",
    "Proclear toric",
    "Proclear XR toric",
    "Proclear multifocal toric",
  ];

  power = parseFloat(power);
  cyl = parseFloat(cyl);

  const minimumAxisOptions = ["010", "020", "090", "160", "170", "180"];
  const reducedAxisOptions = [
    "010",
    "020",
    "060",
    "070",
    "080",
    "090",
    "100",
    "110",
    "120",
    "160",
    "170",
    "180",
  ];
  const semireducedAxisOptions = [
    "010",
    "020",
    "070",
    "080",
    "090",
    "100",
    "110",
    "160",
    "170",
    "180",
  ];

  const xrArray = [
    "Biofinity toric",
    "Biofinity toric multifocal",
    "Proclear XR toric",
    "Proclear multifocal toric",
  ];

  // Check if the axis value ends in "5"
  if (axis.toString().endsWith("5")) {
    return xrArray; // Return xrArray if the axis ends in "5"
  }

  let filteredLensNames = initialLensArray;

  if (minimumAxisOptions.includes(axis)) {
    filteredLensNames = initialLensArray; //
  } else if (reducedAxisOptions.includes(axis)) {
    if ((!power || (power >= -6 && power <= 0)) && (!cyl || cyl >= -1.75)) {
      filteredLensNames = initialLensArray.filter((lens) =>
        lens.includes("clariti 1 day toric")
      );
    }
  } else if (
    (!power || (power >= -6 && power <= 0)) &&
    (!cyl || cyl >= -2.25) &&
    semireducedAxisOptions.includes(axis)
  ) {
    filteredLensNames = initialLensArray.filter((lens) =>
      lens.includes("clariti 1 day toric")
    );
  } else if (
    (!power || power <= -6.5) &&
    (!cyl || cyl >= -1.75) &&
    reducedAxisOptions.includes(axis)
  ) {
    filteredLensNames = initialLensArray.filter((lens) =>
      lens.includes("clariti 1 day toric")
    );
  } else if (
    (!power || power <= -6.5) &&
    (!cyl || cyl >= -2.25) &&
    minimumAxisOptions.includes(axis)
  ) {
    filteredLensNames = initialLensArray.filter((lens) =>
      lens.includes("clariti 1 day toric")
    );
  } else if (
    (!power || (power >= 0.25 && power <= 4.0)) &&
    (!cyl || cyl >= -2.25) &&
    semireducedAxisOptions.includes(axis)
  ) {
    filteredLensNames = initialLensArray.filter((lens) =>
      lens.includes("clariti 1 day toric")
    );
  }

  return filteredLensNames;
};

export default LensByAxisCooper;
