import React from "react";

const AxisDropdown = ({ filters, axisOptions, handleFilterChange }) => {
  // Update the axis filter directly using handleFilterChange
  const handleAxisSelection = (event) => {
    const newSelectedAxis = event.target.value;
    handleFilterChange({
      target: { name: "axis", value: newSelectedAxis },
    });
  };

  return (
    <div className="input-container-axis">
      <h4 className="label" id="axislabel">
        AXIS
      </h4>
      <select
        className="dropdown"
        value={filters.axis}
        onChange={handleAxisSelection}
      >
        <option value="">---</option>
        {axisOptions.map((axis, index) => (
          <option key={axis} value={axis}>
            {axis}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AxisDropdown;
