const LensByAxisAlcon = ({ power, axis, cyl }) => {
  const initialLensArray = [
    "Air Optix plus HydraGlyde for Astigmatism",
    "Dailies AquaComfort Plus Toric",
    "Dailies Total 1 for Astigmatism",
    "Precision1 for Astigmatism",
    "TOTAL30 for Astigmatism",
  ];

  power = parseFloat(power);
  cyl = parseFloat(cyl);

  const minimumAxisOptions = ["010", "020", "160", "170", "180"];
  const reducedAxisOptions = [
    "010",
    "020",
    "070",
    "080",
    "090",
    "100",
    "110",
    "160",
    "170",
    "180",
  ];

  let filteredLensNames = [
    "Air Optix plus HydraGlyde for Astigmatism",
    "TOTAL30 for Astigmatism",
  ];

  // Check if the axis value ends with "0", if not, return an empty array
  if (!axis.endsWith("0")) {
    return [];
  }

  if (minimumAxisOptions.includes(axis)) {
    filteredLensNames = initialLensArray; // All lenses available for minimum axis options
  } else if (reducedAxisOptions.includes(axis)) {
    filteredLensNames.push("Dailies AquaComfort Plus Toric");
    if (!power || (power >= -6 && power <= 0) || !cyl || cyl === -2.25) {
      filteredLensNames.push(
        "Precision1 for Astigmatism",
        "Dailies Total 1 for Astigmatism"
      );
    }
  } else if (!cyl || cyl >= -1.75) {
    filteredLensNames.push(
      "Precision1 for Astigmatism",
      "Dailies Total 1 for Astigmatism"
    );
  }

  return filteredLensNames;
};

export default LensByAxisAlcon;
