const LensByAxisMenicon = ({ power, axis, cyl }) => {
  const initialLensArray = ["Miru 1month toric", "Miru 1day Flat Pack Toric"];

  power = parseFloat(power);
  cyl = parseFloat(cyl);

  const minimumAxisOptions = ["015", "090", "165", "180"];
  const fullAxisOptions = [
    "010",
    "020",
    "030",
    "040",
    "050",
    "060",
    "070",
    "080",
    "090",
    "100",
    "110",
    "120",
    "130",
    "140",
    "150",
    "160",
    "170",
    "180",
  ];

  let filteredLensNames = [];

  if (fullAxisOptions.includes(axis)) {
    filteredLensNames.push("Miru 1month toric");
  }
  if (
    (minimumAxisOptions.includes(axis) && cyl >= -1.25) ||
    (axis === "180" && cyl === -1.75)
  ) {
    filteredLensNames.push("Miru 1day Flat Pack Toric");
  }

  return filteredLensNames;
};

export default LensByAxisMenicon;
