import React from "react";

const AxisDisplayBausch = ({ lensName, cyl }) => {
  let additionalText = "";

  const fullRange = "Full range in 10° steps";
  const highBauschRange =
    "010, 020, 060, 070, 080, 090, 100, 110, 120, 160, 170, 180";
  const reducedBauschRange = "010, 020, 070, 080, 090, 100, 110, 160, 170, 180";
  const lowBauschRange = "010, 020, 080, 090, 100, 160, 170, 180";
  const minimumBauschRange = "010, 020, 090, 160, 170, 180";

  switch (lensName) {
    case "Biotrue ONEday for Astigmatism":
      if (cyl >= -1.75) {
        additionalText = (
          <div>
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: pl to -6.00</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{fullRange}</span>
            </div>
            {/* high minus */}
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: -6.50 to -9.00:</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{highBauschRange}</span>
            </div>
            {/* plus */}
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: +0.50 to +4.00:</span>
            </div>
            <div className="cylRange2">
              <span className="cylDisplaySpan">{reducedBauschRange}</span>
            </div>
          </div>
        );
      } else if (cyl === -2.25) {
        additionalText = (
          <div>
            {/* low minus */}
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: pl to -6.00</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{reducedBauschRange}</span>
            </div>
            {/* high minus */}
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: -6.50 to -9.00:</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{minimumBauschRange}</span>
            </div>
            {/* plus */}
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: +0.50 to +4.00:</span>
            </div>
            <div className="cylRange2">
              <span className="cylDisplaySpan">{lowBauschRange}</span>
            </div>
          </div>
        );
      } else if (cyl === -2.75) {
        additionalText = (
          <div>
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: pl to -6.00</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{minimumBauschRange}</span>
            </div>
          </div>
        );
      }
      break;

    case "Ultra for Astigmatism":
      additionalText = (
        <>
          <div className="cylSphDiv">
            <span className="cylSpan">Sph: all powers (-9.00 to +6.00)</span>
          </div>
          <div className="cylRange1">
            <span className="cylDisplaySpan">{fullRange}</span>
          </div>
        </>
      );
      break;

    case "Ultra Multifocal for Astigmatism":
      if (cyl >= -1.75) {
        additionalText = (
          <>
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: all powers (-6.00 to +4.00)</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{fullRange}</span>
            </div>
          </>
        );
      } else if (cyl >= -2.75) {
        additionalText = (
          <>
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: all powers (-6.00 to +4.00)</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{reducedBauschRange}</span>
            </div>
          </>
        );
      }
      break;

    case "PureVision2 for Astigmatism":
      // ****DO NOT DELETE UNLESS YOU ARE SURE - NOT CODE-RELATED****
      // ****Commented code is the currently available lenses, non-commented includes discontinued lenses****

      // additionalText = (
      //   <>
      //     <div className="cylSphDiv">
      //       <span className="cylSpan">Sph: all powers (pl to -9.00)</span>
      //     </div>
      //     <div className="cylRange1">
      //       <span className="cylDisplaySpan">{lowBauschRange}</span>
      //     </div>
      //   </>
      // );
      additionalText = (
        <>
          <div className="cylSphDiv">
            <span className="cylSpan">Sph: all powers (-9.00 to +6.00)</span>
          </div>
          <div className="cylRange1">
            <span className="cylDisplaySpan">{fullRange}</span>
          </div>
        </>
      );
      break;

    case "SofLens Toric":
      // **** Discontinued lenses: +0.25 to +6.00, cyl: -0.75, -1.25, -1.75, -2.25, all axes
      // **** Discontinued lenses: pl, cyl: -0.75, -1.25, -1.75, -2.25, -2.75, all axes

      additionalText = (
        <>
          <div className="cylSphDiv">
            <span className="cylSpan">Sph: all powers (-0.25 to -9.00)</span>
          </div>
          <div className="cylRange1">
            <span className="cylDisplaySpan">{fullRange}</span>
          </div>
        </>
      );
      break;

    case "SofLens Daily Disposable for Astigmatism":
      // **** all discontinued in the US ****
      additionalText = (
        <>
          <div className="cylSphDiv">
            <span className="cylSpan">Sph: all powers (pl to -9.00)</span>
          </div>
          <div className="cylRange1">
            <span className="cylDisplaySpan">{fullRange}</span>
          </div>
        </>
      );
      break;
    default:
      additionalText = "Additional details.";
      break;
  }

  return <div>{additionalText}</div>;
};

export default AxisDisplayBausch;
