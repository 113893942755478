import React, { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import CylDropdown from "./CylDropdown";
import AxisDropdown from "./AxisDropdown";
import SidebarSection from "./SidebarSection";
import privateArray from "./assets/privateArray";
import StoreCard from "./StoreCard";

function Sidebar(props) {
  const {
    filters,
    handleFilterChange,
    handleCheckboxChange,
    setFilters,
    collapsed,
    setCollapsed,
    lenses,
    fiveDegreeSteps,
    setFiveDegreeSteps,
    handleFiveDegreeStepsChange,
    searchInput,
    setSearchInput,
    handleSearchInputChange,
    setGroup1,
    setGroup2,
    setGroup3,
    setGroup4,
  } = props;

  const [axisOptions, setAxisOptions] = useState([]);
  const [showStoreCard, setShowStoreCard] = useState(false);
  const [selectedStore, setSelectedStore] = useState("");

  const toggleStoreCard = () => {
    setShowStoreCard(!showStoreCard);
  };

  useEffect(() => {
    const options = fiveDegreeSteps
      ? Array.from({ length: 36 }, (_, i) =>
          ((i + 1) * 5).toString().padStart(3, "0")
        )
      : Array.from({ length: 18 }, (_, i) =>
          ((i + 1) * 10).toString().padStart(3, "0")
        );
    setAxisOptions(options);
  }, [fiveDegreeSteps]);

  const [collapsedSections, setCollapsedSections] = useState({
    manuf: false,
    replacementSchedule: false,
    lensType: false,
    sph: false,
    cylDropdown: false,
    axisDropdown: false,
    fiveDegreeSteps: false,
    clearFilters: false,
  });

  const toggleSectionCollapsed = (section) => {
    setCollapsedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const handleClear = () => {
    setFilters({
      power: "",
      cyl: "",
      axis: "",
      fiveDegreeSteps: false,
      schedule: "",
      manuf: "",
      siHyChecked: false,
      hydrogelChecked: false,
      extended: false,
      color: false,
      bandage: false,
      Store: "",
    });
    setFiveDegreeSteps(false);
    setSearchInput("");
    setGroup1(false);
    setGroup2(false);
    setGroup3(false);
    setGroup4(false);
  };

  const handleLensTypeChange = (e) => {
    const { name, checked } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: checked,
    }));
  };

  const [paddingTop, setPaddingTop] = useState("90px");

  useEffect(() => {
    const handleResize = () => {
      const viewportHeight = window.innerHeight;
      const newPaddingTop = viewportHeight < 670 ? "10px" : "90px";
      setPaddingTop(newPaddingTop);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the event listener
    };
  }, []);

  const handleStoreSelection = (store) => {
    if (selectedStore !== store) {
      setSelectedStore(store);
      setShowStoreCard(true);
    } else {
      setShowStoreCard(!showStoreCard);
    }
  };

  return (
    <div
      className={`sidebar ${collapsed ? "collapsed" : ""}`}
      style={{ paddingTop }}
    >
      <div className="sidebar-content">
        {/* Search Input */}
        <input
          type="text"
          placeholder="Search..."
          value={searchInput}
          onChange={handleSearchInputChange}
        />

        {/* SPH / CYL / AXIS Section */}
        <SidebarSection
          className="sidebarsection"
          id="sca"
          title="SPH / CYL / AXIS"
          collapsed={collapsedSections.sph}
          onToggle={() => toggleSectionCollapsed("sph")}
          defaultCollapsed={true}
          filters={filters} //// added
        >
          {/* SPH Input */}
          {!collapsedSections.sph && (
            <>
              <label className="sph-label">
                <h4 className="sph-title">
                  SPH{" "}
                  {
                    <input
                      id="sph-input"
                      type="text"
                      name="power"
                      value={filters.power}
                      onChange={handleFilterChange}
                      style={{ width: "50px" }} // Adjust width as needed
                    />
                  }
                </h4>
              </label>
            </>
          )}

          {/* Cylinder Dropdown */}
          {!collapsedSections.cylDropdown && (
            <CylDropdown
              filters={filters}
              handleFilterChange={handleFilterChange}
              lenses={lenses}
            />
          )}

          {/* Axis Dropdown */}
          {!collapsedSections.axisDropdown && (
            <AxisDropdown
              id="axisdropdown"
              className="sidebarOption"
              cyl={filters.cyl}
              power={filters.power}
              filters={filters}
              axisOptions={axisOptions}
              lenses={lenses}
              handleFilterChange={handleFilterChange}
            />
          )}

          {/* Five Degree Steps Checkbox */}
          {!collapsedSections.fiveDegreeSteps && (
            <div id="fivedeg">
              <input
                type="checkbox"
                id="fiveDegreeSteps"
                checked={fiveDegreeSteps}
                onChange={handleFiveDegreeStepsChange}
                name="fiveDegreeSteps"
              />
              <label id="fivedegtext">
                5<sup>°</sup> steps
              </label>
            </div>
          )}
          <button
            id="sca-clear-button"
            className="clear-button"
            onClick={() => {
              setFilters({ ...filters, power: "", cyl: "", axis: "" });
              setFiveDegreeSteps(false);
            }}
          >
            Clear
          </button>
        </SidebarSection>

        {/* Manufacturer Section */}
        <SidebarSection
          className="sidebarsection"
          title="Manufacturer"
          collapsed={collapsedSections.manuf}
          onToggle={() => toggleSectionCollapsed("manuf")}
          defaultCollapsed={true}
        >
          {!collapsedSections.manuf && (
            <>
              {[
                "Vistakon",
                "Bausch + Lomb",
                "CooperVision",
                "Alcon",
                "Menicon",
              ].map((manuf) => (
                <div className="sidebarOption" key={manuf}>
                  <label className="sidebar-section-label">
                    <input
                      className="radio"
                      type="radio"
                      name="manuf"
                      value={manuf}
                      checked={filters.manuf === manuf}
                      onChange={handleFilterChange}
                    />
                    {manuf}
                  </label>
                </div>
              ))}
              <button
                className="clear-button"
                onClick={() => setFilters({ ...filters, manuf: "" })}
              >
                Clear
              </button>
            </>
          )}
        </SidebarSection>

        {/* Replacement Schedule Section */}
        <SidebarSection
          className="sidebarsection"
          title="Replacement"
          collapsed={collapsedSections.replacementSchedule}
          onToggle={() => toggleSectionCollapsed("replacementSchedule")}
          defaultCollapsed={true}
        >
          {!collapsedSections.replacementSchedule && (
            <>
              {["DD", "2W", "1M"].map((schedule) => (
                <div className="sidebarOption" key={schedule}>
                  <label className="sidebar-section-label">
                    <input
                      type="radio"
                      name="schedule"
                      value={schedule}
                      checked={filters.schedule === schedule}
                      onChange={handleFilterChange}
                    />
                    {schedule === "DD"
                      ? "Daily Disposable"
                      : schedule === "2W"
                      ? "Two-Week"
                      : "Monthly"}
                  </label>
                </div>
              ))}
              <label className="sidebar-section-label" id="ew-label">
                <input
                  type="checkbox"
                  name="extended"
                  checked={filters.extended}
                  onChange={(e) => {
                    handleCheckboxChange(e);
                  }}
                />
                Extended wear
              </label>
              <div>
                <button
                  className="clear-button"
                  onClick={() => {
                    setFilters({
                      ...filters,
                      schedule: "",
                      extended: false,
                    });
                  }}
                >
                  Clear
                </button>
              </div>
            </>
          )}
        </SidebarSection>

        {/* Lens Type Section */}
        <SidebarSection
          className="sidebarsection"
          title="Lens Type"
          collapsed={collapsedSections.lensType}
          onToggle={() => toggleSectionCollapsed("lensType")}
          defaultCollapsed={true}
        >
          {!collapsedSections.lensType && (
            <>
              <label className="sidebarCheckbox">
                <input
                  type="checkbox"
                  name="toric"
                  checked={filters.toric}
                  onChange={(e) => {
                    handleFilterChange(e);
                    handleCheckboxChange(e);
                  }}
                />
                Toric
              </label>

              <label className="sidebarCheckbox">
                <input
                  style={{ marginLeft: "7px" }}
                  type="checkbox"
                  name="multifocal"
                  checked={filters.multifocal}
                  onChange={(e) => {
                    handleFilterChange(e);
                    handleCheckboxChange(e);
                  }}
                />
                Multifocal
              </label>
              <div id="lens-type-row2">
                <label className="sidebarCheckbox">
                  <input
                    type="checkbox"
                    name="color"
                    checked={filters.color}
                    onChange={(e) => {
                      handleFilterChange(e);
                      handleCheckboxChange(e);
                    }}
                  />
                  Color
                </label>
                <label className="sidebarCheckbox">
                  <input
                    type="checkbox"
                    name="bandage"
                    checked={filters.bandage}
                    onChange={(e) => {
                      handleFilterChange(e);
                      handleCheckboxChange(e);
                    }}
                  />
                  Bandage
                </label>
              </div>
            </>
          )}
        </SidebarSection>

        {/* Material Section */}
        <SidebarSection
          className="sidebarsection"
          title="Material"
          collapsed={collapsedSections.material}
          onToggle={() => toggleSectionCollapsed("material")}
          defaultCollapsed={true}
        >
          <div className="checkbox-inline">
            <label
              className="sidebar-section-label"
              style={{ marginRight: "17px" }}
            >
              <input
                type="checkbox"
                name="siHyChecked"
                checked={filters.siHyChecked}
                onChange={handleLensTypeChange}
              />
              SiHy
            </label>
            <label>
              <input
                type="checkbox"
                name="hydrogelChecked"
                checked={filters.hydrogelChecked}
                onChange={handleLensTypeChange}
              />
              Hydrogel
            </label>
          </div>
          <hr className="materialhr" />
          <span id="dktspan">Dk/t</span>
          <div>
            <div className="dktdiv">
              <span>
                <label>
                  <input
                    type="checkbox"
                    name="group1"
                    checked={filters.group1}
                    onChange={handleCheckboxChange}
                  />
                  &nbsp;&lt; 27
                </label>
              </span>
              <span id="g2span">
                <label>
                  <input
                    type="checkbox"
                    name="group2"
                    checked={filters.group2}
                    onChange={handleCheckboxChange}
                  />
                  &nbsp;27 - 86
                </label>
              </span>
            </div>
            <div className="dktdiv">
              <span>
                <label>
                  <input
                    type="checkbox"
                    name="group3"
                    checked={filters.group3}
                    onChange={handleCheckboxChange}
                  />
                  &nbsp;87 - 141
                </label>
              </span>
              <span id="g4span">
                <label>
                  <input
                    type="checkbox"
                    name="group4"
                    checked={filters.group4}
                    onChange={handleCheckboxChange}
                  />
                  &nbsp;&gt;141
                </label>
              </span>
            </div>
          </div>
        </SidebarSection>
        {window.innerWidth >= 491 && (
          <SidebarSection
            className="sidebarsection, private-label-section"
            title="Private Labels"
            collapsed={collapsedSections.Store}
            onToggle={() => toggleSectionCollapsed("Store")}
            defaultCollapsed={true}
          >
            {!collapsedSections.Store && (
              <>
                <div style={{ fontSize: "13px", paddingLeft: "4px" }}>
                  To search by name, use the search box.
                </div>
                <hr className="materialhr" />
                {[
                  "1-800 Contacts",
                  "America's Best",
                  "Costco",
                  "Eyeglass World",
                  "LensCrafters",
                  "Target Optical",
                  "Pearle Vision",
                  "Vision Source",
                  "Visionworks",
                  "Warby Parker",
                  "other",
                ].map((Store) => (
                  <div className="sidebarOption privateOption" key={Store}>
                    <button
                      className={`undecorated-button ${
                        selectedStore === Store ? "active" : ""
                      }`}
                      onClick={() => {
                        setShowStoreCard(!showStoreCard);
                        handleStoreSelection(Store);
                      }}
                      style={{
                        border: "none",
                        background: "none",
                        color: "inherit",
                        padding: 0,
                        cursor: "pointer",
                        fontWeight: "normal",
                      }}
                      onMouseOver={(e) => {
                        e.target.style.fontWeight = "bold";
                      }}
                      onMouseOut={(e) => {
                        e.target.style.fontWeight = "normal";
                      }}
                    >
                      {Store}
                    </button>
                  </div>
                ))}
              </>
            )}
          </SidebarSection>
        )}
        {showStoreCard && (
          <StoreCard
            show={showStoreCard}
            onClose={toggleStoreCard}
            handleStoreSelection={handleStoreSelection}
            privateLabels={privateArray}
            selectedStore={selectedStore}
          />
        )}
        <div
          title="Clear All Filters"
          collapsed={collapsedSections.clearFilters}
          onToggle={() => toggleSectionCollapsed("clearFilters")}
        >
          {!collapsedSections.clearFilters && (
            <button className="clear-all-button" onClick={handleClear}>
              Clear All Filters
            </button>
          )}
        </div>
      </div>

      {/* Chevron Icon */}
      {window.innerWidth <= 768 && (
        <div
          style={{
            position: "absolute",
            top: "49.2%",
            right: "1px",
            transform: "translateY(-50%)",
            zIndex: 1000,
          }}
        >
          <FaChevronRight
            className={`chevron-icon ${
              collapsed ? "chevron-normal" : "chevron-rotated"
            }`}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              cursor: "pointer",
              color: "#aaa",
              opacity: "0.5",
              position: "relative",
              left: collapsed ? "-1px" : "6px",
            }}
          />

          {/* Vertical Text */}
          {collapsed && (
            <div
              className="vertical-text"
              style={{
                position: "absolute",
                top: "-100%",
                right: "-16px",
                transform: "translateY(-75%) rotate(90deg)",
                fontSize: "20px",
                color: "#aaa",
              }}
            >
              Filters
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Sidebar;
