import React from "react";

const AxisDisplayCooper = ({ lensName, cyl }) => {
  let additionalText = "";

  const fullRange = "Full range in 10° steps";
  const extendedRange = "Full range in 5° steps";
  const highCooperRange =
    "010, 020, 060, 070, 080, 090, 100, 110, 120, 160, 170, 180";
  const reducedCooperRange = "010, 020, 070, 080, 090, 100, 110, 160, 170, 180";
  const minimumCooperRange = "010, 020, 090, 160, 170, 180";

  switch (lensName) {
    case "Avaira Vitality Toric":
      additionalText = (
        <>
          <div className="cylSphDiv">
            <span className="cylSpan">Sph: all powers (-10.00 to +8.00)</span>
          </div>
          <div className="cylRange1">
            <span className="cylDisplaySpan">{fullRange}</span>
          </div>
        </>
      );
      break;
    case "Biofinity toric":
      additionalText = (
        <>
          <div className="cylSphDiv">
            <span className="cylSpan">Sph: all powers (-10.00 to +8.00)</span>
          </div>
          <div className="cylRange1">
            <span className="cylDisplaySpan">{fullRange}</span>
          </div>
        </>
      );
      break;
    case "Biofinity XR toric":
      if (cyl >= -2.25) {
        additionalText = (
          <div>
            {/* low minus */}
            {/* high minus */}
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: -10.50 to -20.00:</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{highCooperRange}</span>
            </div>
            {/* plus */}
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: +8.50 to +20.00:</span>
            </div>
            <div className="cylRange2">
              <span className="cylDisplaySpan">{reducedCooperRange}</span>
            </div>
          </div>
        );
      } else if (cyl >= -5.75) {
        additionalText = (
          <>
            <div className="cylSphDiv">
              <span className="cylSpan">
                Sph: all powers (-10.00 to +10.00)
              </span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{extendedRange}</span>
            </div>
          </>
        );
      }
      break;
    case "Biofinity toric multifocal":
      additionalText = (
        <>
          <div className="cylSphDiv">
            <span className="cylSpan">Sph: all powers (-10.00 to +10.00)</span>
          </div>
          <div className="cylRange1">
            <span className="cylDisplaySpan">{extendedRange}</span>
          </div>
        </>
      );
      break;
    case "Biomedics toric":
      additionalText = (
        <>
          <div className="cylSphDiv">
            <span className="cylSpan">Sph: all powers (-10.00 to +6.00)</span>
          </div>
          <div className="cylRange1">
            <span className="cylDisplaySpan">{fullRange}</span>
          </div>
        </>
      );
      break;
    case "clariti 1 day toric":
      if (cyl >= -1.75) {
        additionalText = (
          <div>
            {/* low minus */}
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: pl to -6.00</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{fullRange}</span>
            </div>
            {/* high minus */}
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: -6.50 to -9.00:</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{highCooperRange}</span>
            </div>
            {/* plus */}
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: +0.25 to +4.00:</span>
            </div>
            <div className="cylRange2">
              <span className="cylDisplaySpan">{reducedCooperRange}</span>
            </div>
          </div>
        );
      } else if (cyl === -2.25) {
        additionalText = (
          <div>
            {/* low minus */}
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: pl to -6.00</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{reducedCooperRange}</span>
            </div>
            {/* high minus */}
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: -6.50 to -9.00:</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{minimumCooperRange}</span>
            </div>
          </div>
        );
      }
      break;
    case "MyDay toric":
      additionalText = (
        <>
          <div className="cylSphDiv">
            <span className="cylSpan">Sph: all powers (-10.00 to +8.00)</span>
          </div>
          <div className="cylRange1">
            <span className="cylDisplaySpan">{fullRange}</span>
          </div>
        </>
      );
      break;
    case "Proclear multifocal toric":
      additionalText = (
        <>
          <div className="cylSphDiv">
            <span className="cylSpan">Sph: all powers (-20.00 to +20.00)</span>
          </div>
          <div className="cylRange1">
            <span className="cylDisplaySpan">{extendedRange}</span>
          </div>
        </>
      );
      break;
    case "Proclear toric":
      additionalText = (
        <>
          <div className="cylSphDiv">
            <span className="cylSpan">Sph: all powers (-8.00 to +6.00)</span>
          </div>
          <div className="cylRange1">
            <span className="cylDisplaySpan">{fullRange}</span>
          </div>
        </>
      );
      break;
    case "Proclear XR toric":
      if (cyl >= -2.25) {
        additionalText = (
          <div>
            {/* low minus */}
            {/* high minus */}
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: -8.50 to -10.00:</span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{highCooperRange}</span>
            </div>
            {/* plus */}
            <div className="cylSphDiv">
              <span className="cylSpan">Sph: +6.25 to +10.00:</span>
            </div>
            <div className="cylRange2">
              <span className="cylDisplaySpan">{reducedCooperRange}</span>
            </div>
          </div>
        );
      } else if (cyl >= -5.75) {
        additionalText = (
          <>
            <div className="cylSphDiv">
              <span className="cylSpan">
                Sph: all powers (-10.00 to +10.00)
              </span>
            </div>
            <div className="cylRange1">
              <span className="cylDisplaySpan">{extendedRange}</span>
            </div>
          </>
        );
      }
      break;

    default:
      additionalText = "Additional details.";
      break;
  }

  return <div>{additionalText}</div>;
};

export default AxisDisplayCooper;
