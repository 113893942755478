import React, { useState } from "react";

export default function AdditionalPopup({ lens }) {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div>
      <button className="additional-button" onClick={togglePopup}>
        More info
      </button>
      {showPopup && (
        <div className="additional-popup">
          <span style={{ fontWeight: "bold" }}>Material:</span>{" "}
          <span>{lens.material}</span>
          <span style={{ fontWeight: "bold" }}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <span style={{ fontWeight: "bold" }}>
            H<sub>2</sub>O:
          </span>
          <span> {lens.water}%</span>
          <span style={{ fontWeight: "bold" }}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <span style={{ fontWeight: "bold" }}>UV Class:</span>{" "}
          <span>{lens.Uvclass}</span>
        </div>
      )}
    </div>
  );
}
