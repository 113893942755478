const LensByAxisBausch = ({ power, axis, cyl }) => {
  const initialLensArray = [
    "Biotrue ONEday for Astigmatism",
    "Ultra for Astigmatism",
    "Ultra Multifocal for Astigmatism",
    "PureVision2 for Astigmatism",
    "SofLens Toric",
    "SofLens Daily Disposable for Astigmatism",
  ];

  power = parseFloat(power);
  cyl = parseFloat(cyl);

  const firstAxisOptions = ["010", "020", "090", "160", "170", "180"];
  const secondAxisOptions = [...firstAxisOptions, "080", "100"];
  const thirdAxisOptions = [...secondAxisOptions, "070", "110"];
  const fourthAxisOptions = [...thirdAxisOptions, "060", "120"];

  let filteredLensNames = [
    "Ultra for Astigmatism",
    "PureVision2 for Astigmatism",
    "SofLens Toric",
    "SofLens Daily Disposable for Astigmatism",
  ];

  if (!axis.endsWith("0")) {
    return [];
  }

  if (firstAxisOptions.includes(axis)) {
    filteredLensNames = initialLensArray;
  } else if (
    (secondAxisOptions.includes(axis) &&
      (!power || power >= -6.0) &&
      (!cyl || cyl >= -2.25)) ||
    ((!power || power <= -6.5) && (!cyl || cyl >= -1.75))
  ) {
    filteredLensNames.push("Biotrue ONEday for Astigmatism");
  } else if (
    thirdAxisOptions.includes(axis) &&
    (!power || power <= 0 || !cyl || cyl >= -1.75)
  ) {
    filteredLensNames.push("Biotrue ONEday for Astigmatism");
  } else if (fourthAxisOptions.includes(axis)) {
    if ((!power || (power >= -6 && power <= 0)) && (!cyl || cyl >= -1.75)) {
      filteredLensNames.push("Biotrue ONEday for Astigmatism");
    }
  }

  return filteredLensNames;
};

export default LensByAxisBausch;
