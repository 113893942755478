import React, { useState, useEffect } from "react";

const StoreCard = ({ show, onClose, selectedStore, privateLabels }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const filteredPrivateLabels =
    !selectedStore || selectedStore.toLowerCase() === "other"
      ? privateLabels.filter(
          (item) => !item.Store || item.Store.toLowerCase().includes("other")
        )
      : privateLabels.filter(
          (item) =>
            item.Store &&
            item.Store.toLowerCase().includes(selectedStore.toLowerCase())
        );

  const fontSize =
    filteredPrivateLabels.length > 10 || isSmallScreen ? "60%" : "100%";
  const leftPosition = isSmallScreen ? "250px" : "386px";
  const topPosition = isSmallScreen ? "300px" : "400px";

  return (
    <div
      className={`store-card ${show ? "show" : ""}`}
      style={{
        fontSize,
        left: leftPosition,
        top: topPosition,
      }}
    >
      <div className="store-content">
        <span onClick={onClose} className="close">
          &times;
        </span>
        <h2 style={{ marginTop: "8px", marginBottom: "3px", fontSize: "19px" }}>
          {selectedStore}
        </h2>
        <hr
          style={{ opacity: 0.25, paddingRight: "100px", maxWidth: "100%" }}
        />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridColumnGap: "10px",
          }}
        >
          <div
            style={{
              textAlign: "left",
              fontSize: "14px",
              fontWeight: "bold",
              paddingBottom: "8px",
            }}
          >
            Private Label:
          </div>
          <div
            style={{
              textAlign: "left",
              fontSize: "14px",
              fontWeight: "bold",
              paddingBottom: "8px",
            }}
          >
            Original Label:
          </div>
        </div>
        {filteredPrivateLabels.map((item, index, array) => (
          <div
            key={item.Private}
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridColumnGap: "10px",
              paddingBottom: "5px",
              marginBottom: index !== array.length - 1 ? "5px" : "0",
              borderBottom:
                index !== array.length - 1 ? "1px solid #ccc" : "none",
            }}
          >
            <div style={{ textAlign: "left" }}>{item.Private}</div>{" "}
            <div style={{ textAlign: "left" }}>{item.Original}</div>{" "}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StoreCard;
