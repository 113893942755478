const lensArray = [
  {
    name: "Oasys Max 1-Day",
    brand: "Oasys",
    manuf: "Vistakon",
    schedule: "DD",
    nameID: "V001",
    toric: false,
    multifocal: false,
    color: false,
    multBC: true,
    BC: "8.5, 9.0",
    BC1: "8.5",
    BC2: "9.0",
    diam: 14.3,
    pl: false,
    minusQuarter: false,
    plusQuarter: false,
    maxMinus: -12,
    maxPlus: 8,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "senofilcon A",
    sihyhg: "SiHy",
    dkt: 121,
    water: 38,
    Uvclass: "1",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Oasys Max 1-Day Multifocal",
    brand: "Oasys",
    manuf: "Vistakon",
    schedule: "DD",
    nameID: "V002",
    toric: false,
    multifocal: true,
    mfOptions: "LOW, MID, HIGH",
    color: false,
    multBC: false,
    BC: "8.4",
    BC1: "8.4",
    diam: 14.3,
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -9,
    maxPlus: 6,
    fiftySteps: false,
    bandage: false,
    extended: false,
    material: "senofilcon A",
    sihyhg: "SiHy",
    dkt: 103,
    water: 38,
    Uvclass: "1",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Oasys 1-Day",
    brand: "Oasys",
    manuf: "Vistakon",
    schedule: "DD",
    nameID: "V003",
    toric: false,
    multifocal: false,
    color: false,
    multBC: true,
    BC: "8.5, 9.0",
    BC1: "8.5",
    BC2: "9.0",
    diam: 14.3,
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -12,
    maxPlus: 8,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "senofilcon A",
    sihyhg: "SiHy",
    dkt: 121,
    water: 38,
    Uvclass: "1",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Oasys 1-Day for Astigmatism",
    brand: "Oasys",
    manuf: "Vistakon",
    schedule: "DD",
    nameID: "V004",
    toric: true,
    maxCyl: -2.25,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.5",
    BC1: "8.5",
    diam: 14.3,
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -9,
    maxPlus: 4,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "senofilcon A",
    sihyhg: "SiHy",
    dkt: 129,
    water: 38,
    Uvclass: "1",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Moist",
    brand: "Moist",
    manuf: "Vistakon",
    schedule: "DD",
    nameID: "V005",
    toric: false,
    multifocal: false,
    color: false,
    multBC: true,
    BC: "8.5, 9.0",
    BC1: "8.5",
    BC2: "9.0",
    diam: 14.2,
    pl: false,
    minusQuarter: false,
    plusQuarter: false,
    maxMinus: -12,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "etafilcon A",
    sihyhg: "HG",
    dkt: 25.5,
    water: 58,
    Uvclass: "2",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Moist for Astigmatism",
    brand: "Moist",
    manuf: "Vistakon",
    schedule: "DD",
    nameID: "V006",
    toric: true,
    maxCyl: -2.25,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.5",
    BC1: "8.5",
    diam: 14.5,
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -9,
    maxPlus: 4,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "etafilcon A",
    sihyhg: "HG",
    dkt: 23.8,
    water: 58,
    Uvclass: "2",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Moist Multifocal",
    brand: "Moist",
    manuf: "Vistakon",
    schedule: "DD",
    nameID: "V007",
    toric: false,
    multifocal: true,
    mfOptions: "LOW, MID, HIGH",
    color: false,
    multBC: false,
    BC: "8.4",
    BC1: "8.4",
    diam: 14.3,
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -9,
    maxPlus: 6,
    fiftySteps: false,
    bandage: false,
    extended: false,
    material: "etafilcon A",
    sihyhg: "HG",
    dkt: 25.5,
    water: 58,
    Uvclass: "2",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Oasys",
    brand: "Oasys",
    manuf: "Vistakon",
    schedule: "2W",
    nameID: "V008",
    toric: false,
    multifocal: false,
    color: false,
    multBC: true,
    BC: "8.4, 8.8",
    BC1: "8.4",
    BC2: "8.8",
    diam: "14.0",
    pl: false,
    minusQuarter: false,
    plusQuarter: false,
    maxMinus: -12,
    maxPlus: 8,
    fiftySteps: true,
    bandage: true,
    extended: true,
    extendedNotes: "7 days/6 nights, discard after 1 week",
    material: "senofilcon A",
    sihyhg: "SiHy",
    dkt: 147,
    water: 38,
    Uvclass: "1",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Oasys for Astigmatism",
    brand: "Oasys",
    manuf: "Vistakon",
    schedule: "2W",
    nameID: "V009",
    toric: true,
    maxCyl: -2.75,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.5",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -9,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: true,
    extendedNotes: "7 days/6 nights, discard after 1 week",
    material: "senofilcon A",
    sihyhg: "SiHy",
    dkt: 129.3,
    water: 38,
    Uvclass: "1",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Oasys Multifocal",
    brand: "Oasys",
    manuf: "Vistakon",
    schedule: "2W",
    nameID: "V010",
    toric: false,
    multifocal: true,
    mfOptions: "LOW, MID, HIGH",
    color: false,
    multBC: false,
    BC: "8.4",
    BC1: "8.4",
    diam: "14.3",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -9,
    maxPlus: 6,
    fiftySteps: false,
    bandage: false,
    extended: true,
    extendedNotes: "7 days/6 nights, discard after 1 week",
    material: "senofilcon A",
    sihyhg: "SiHy",
    dkt: 147,
    water: 38,
    Uvclass: "1",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Oasys with Transitions",
    brand: "Oasys",
    manuf: "Vistakon",
    schedule: "2W",
    nameID: "V012",
    toric: false,
    multifocal: false,
    color: true,
    multBC: true,
    BC: "8.4, 8.8",
    BC1: "8.4",
    BC2: "8.8",
    diam: "14.0",
    pl: true,
    minusQuarter: true,
    plusQuarter: false,
    maxMinus: -12,
    maxPlus: 0,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "senofilcon A",
    sihyhg: "SiHy",
    dkt: 121,
    water: 38,
    Uvclass: "1",
    visibilityTint: true,
    notes: "to be discontinued 6/30/24",
    notice: "To be discontinued 6/30/24",
    alert: "To be discontinued 6/30/24",
    discontinued: false,
    ignore: false,
  },
  {
    name: "Vita",
    brand: "Vita",
    manuf: "Vistakon",
    schedule: "1M",
    nameID: "V013",
    toric: false,
    multifocal: false,
    color: false,
    multBC: true,
    BC: "8.4, 8.8",
    BC1: "8.4",
    BC2: "8.8",
    diam: "14.0",
    pl: false,
    minusQuarter: false,
    plusQuarter: false,
    maxMinus: -12,
    maxPlus: 8,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "senofilcon C",
    sihyhg: "SiHy",
    dkt: 147,
    water: 41,
    Uvclass: "1",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Vita for Astigmatism",
    brand: "Vita",
    manuf: "Vistakon",
    schedule: "1M",
    nameID: "V014",
    toric: true,
    maxCyl: -2.25,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.5",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -9,
    maxPlus: 4,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "senofilcon C",
    sihyhg: "SiHy",
    dkt: 129,
    water: 41,
    Uvclass: "1",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Define",
    brand: "Define",
    manuf: "Vistakon",
    schedule: "DD",
    nameID: "V015",
    toric: false,
    multifocal: false,
    color: true,
    multBC: false,
    BC: "8.5",
    BC1: "8.5",
    diam: "14.2",
    pl: true,
    minusQuarter: false,
    plusQuarter: false,
    maxMinus: -9,
    maxPlus: 1,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "etafilcon A",
    sihyhg: "HG",
    dkt: 24.7,
    water: 58,
    Uvclass: "2",
    visibilityTint: true,
    notes: "There is no +0.75",
    discontinued: false,
    ignore: false,
  },
  {
    name: "ACUVUE 2",
    brand: "ACUVUE 2",
    manuf: "Vistakon",
    schedule: "2W",
    nameID: "V017",
    toric: false,
    multifocal: false,
    color: false,
    multBC: true,
    BC: "8.3, 8.7",
    BC1: "8.3",
    BC2: "8.7",
    diam: "14.0",
    pl: false,
    minusQuarter: false,
    plusQuarter: false,
    maxMinus: -12,
    maxPlus: 8,
    fiftySteps: true,
    bandage: false,
    extended: true,
    extendedNotes: "7 days/6 nights",
    material: "etafilcon A",
    sihyhg: "HG",
    dkt: 25.5,
    water: 58,
    Uvclass: "2",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "1-Day ACUVUE TruEye",
    brand: "TruEye",
    manuf: "Vistakon",
    schedule: "DD",
    nameID: "V018",
    toric: false,
    multifocal: false,
    color: false,
    multBC: true,
    BC: "8.5, 9.0",
    BC1: "8.5",
    BC2: 9,
    diam: "14.2",
    pl: false,
    minusQuarter: false,
    plusQuarter: false,
    maxMinus: -12,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "narafilcon A",
    sihyhg: "SiHy",
    dkt: 118,
    water: 46,
    Uvclass: 1,
    visibilityTint: true,
    notice: "Discontinued 4/1/23",
    alert: "Discontinued 4/1/23",
    discontinued: true,
    ignore: true,
  },
  {
    name: "Infuse One-Day",
    brand: "Infuse",
    manuf: "Bausch + Lomb",
    schedule: "DD",
    nameID: "BL01",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.2",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -12,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "kalifilcon A",
    sihyhg: "SiHy",
    dkt: 134,
    water: 55,
    Uvclass: "2",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Infuse One-Day Multifocal",
    brand: "Infuse",
    manuf: "Bausch + Lomb",
    schedule: "DD",
    nameID: "BL02",
    toric: false,
    multifocal: true,
    mfOptions: "Low, High",
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.2",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -10,
    maxPlus: 6,
    fiftySteps: false,
    bandage: false,
    extended: false,
    material: "kalifilcon A",
    sihyhg: "SiHy",
    dkt: 134,
    water: 55,
    Uvclass: "2",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Biotrue ONEday",
    brand: "Biotrue",
    manuf: "Bausch + Lomb",
    schedule: "DD",
    nameID: "BL03",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.2",
    pl: false,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -12,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "nesofilcon A",
    sihyhg: "HG",
    dkt: 42,
    water: 78,
    Uvclass: "2",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Biotrue ONEday for Astigmatism",
    brand: "Biotrue",
    manuf: "Bausch + Lomb",
    schedule: "DD",
    nameID: "BL04",
    toric: true,
    maxCyl: -2.75,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.4",
    BC1: "8.4",
    diam: "14.5",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -9,
    maxPlus: 4,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "nesofilcon A",
    sihyhg: "HG",
    dkt: 42,
    water: 78,
    Uvclass: "2",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Biotrue ONEday for Presbyopia",
    brand: "Biotrue",
    manuf: "Bausch + Lomb",
    schedule: "DD",
    nameID: "BL05",
    toric: false,
    multifocal: true,
    mfOptions: "Low, High",
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.2",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -9,
    maxPlus: 6,
    fiftySteps: false,
    bandage: false,
    extended: false,
    material: "nesofilcon A",
    sihyhg: "HG",
    dkt: 42,
    water: 78,
    Uvclass: "2",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Ultra",
    brand: "Ultra",
    manuf: "Bausch + Lomb",
    schedule: "1M",
    nameID: "BL06",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.5",
    BC1: "8.5",
    diam: "14.2",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -12,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: true,
    extendedNotes: "7 days/6 nights",
    material: "samfilcon A",
    sihyhg: "SiHy",
    dkt: 163,
    water: 46,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Ultra for Astigmatism",
    brand: "Ultra",
    manuf: "Bausch + Lomb",
    schedule: "1M",
    nameID: "BL07",
    toric: true,
    maxCyl: -2.75,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.5",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -9,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: true,
    extendedNotes: "7 days/6 nights",
    material: "samfilcon A",
    sihyhg: "SiHy",
    dkt: 114,
    water: 46,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Ultra for Presbyopia",
    brand: "Ultra",
    manuf: "Bausch + Lomb",
    schedule: "1M",
    nameID: "BL08",
    toric: false,
    multifocal: true,
    mfOptions: "Low, High",
    color: false,
    multBC: false,
    BC: "8.5",
    BC1: "8.5",
    diam: "14.2",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -10,
    maxPlus: 6,
    fiftySteps: false,
    bandage: false,
    extended: true,
    extendedNotes: "7 days/6 nights",
    material: "samfilcon A",
    sihyhg: "SiHy",
    dkt: 163,
    water: 46,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Ultra Multifocal for Astigmatism",
    brand: "Ultra",
    manuf: "Bausch + Lomb",
    schedule: "1M",
    nameID: "BL09",
    toric: true,
    maxCyl: -2.75,
    multifocal: true,
    mfOptions: "Low, High",
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.5",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -6,
    maxPlus: 4,
    fiftySteps: true,
    bandage: false,
    extended: true,
    extendedNotes: "7 days/6 nights",
    material: "samfilcon A",
    sihyhg: "SiHy",
    dkt: 114,
    water: 46,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "PureVision",
    brand: "PureVision",
    manuf: "Bausch + Lomb",
    schedule: "1M",
    nameID: "BL10",
    toric: false,
    multifocal: false,
    color: false,
    multBC: true,
    BC: "8.3, 8.6",
    BC1: "8.3",
    BC2: "8.6",
    diam: "14.0",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -12,
    maxPlus: 6,
    fiftySteps: true,
    bandage: true,
    extended: true,
    extendedNotes: "30 days",
    material: "balafilcon A",
    sihyhg: "SiHy",
    dkt: 96,
    water: 36,
    Uvclass: "none",
    visibilityTint: true,
    notes: "8.6 only; 8.3 is -0.25 to -6",
    notice: "For BC 8.3, limited to -0.25 to -6.00",
    discontinued: false,
    ignore: true,
  },
  {
    name: "PureVision Multi-Focal",
    brand: "PureVision",
    manuf: "Bausch + Lomb",
    schedule: "1M",
    nameID: "BL11",
    toric: false,
    multifocal: true,
    mfOptions: "Low, High",
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.0",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -10,
    maxPlus: 6,
    fiftySteps: false,
    bandage: false,
    extended: true,
    extendedNotes: "30 days",
    material: "balafilcon A",
    sihyhg: "SiHy",
    dkt: 96,
    water: 36,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: true,
  },
  {
    name: "PureVision2",
    brand: "PureVision2",
    manuf: "Bausch + Lomb",
    schedule: "1M",
    nameID: "BL12",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.0",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -12,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: true,
    extendedNotes: "30 days",
    material: "balafilcon A",
    sihyhg: "SiHy",
    dkt: 130,
    water: 36,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: true,
  },
  {
    name: "PureVision2 for Astigmatism",
    brand: "PureVision2",
    manuf: "Bausch + Lomb",
    schedule: "1M",
    nameID: "BL13",
    toric: true,
    maxCyl: -2.25,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.5",
    BC1: "8.5",
    diam: "14.5",
    pl: true,
    minusQuarter: true,
    plusQuarter: false,
    maxMinus: -9,
    maxPlus: 0,
    fiftySteps: true,
    bandage: false,
    extended: true,
    extendedNotes: "30 days",
    material: "balafilcon A",
    sihyhg: "SiHy",
    dkt: 96,
    water: 36,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: true,
  },
  {
    name: "PureVision2 For Presbyopia",
    brand: "PureVision2",
    manuf: "Bausch + Lomb",
    schedule: "1M",
    nameID: "BL14",
    toric: false,
    multifocal: true,
    mfOptions: "Low, High",
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.0",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -10,
    maxPlus: 6,
    fiftySteps: false,
    bandage: false,
    extended: true,
    extendedNotes: "30 days",
    material: "balafilcon A",
    sihyhg: "SiHy",
    dkt: 96,
    water: 36,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: true,
  },
  {
    name: "SofLens 38",
    brand: "SofLens",
    manuf: "Bausch + Lomb",
    schedule: "2W",
    nameID: "BL15",
    toric: false,
    multifocal: false,
    color: false,
    multBC: true,
    BC: "8.4, 8.7, 9.0",
    BC1: "8.4",
    BC2: "8.7",
    BC3: "9.0",
    diam: "14.0",
    pl: false,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -9,
    maxPlus: 4,
    fiftySteps: false,
    bandage: false,
    extended: true,
    extendedNotes: "7 days/6 nights",
    material: "polymacon",
    sihyhg: "HG",
    dkt: 24.3,
    water: 38.6,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "SofLens Toric",
    brand: "SofLens",
    manuf: "Bausch + Lomb",
    schedule: "2W",
    nameID: "BL16",
    toric: true,
    maxCyl: -2.75,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.5",
    BC1: "8.5",
    diam: "14.5",
    pl: false,
    minusQuarter: true,
    plusQuarter: false,
    maxMinus: -9,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: true,
    extendedNotes: "7 days/6 nights",
    material: "alphaflicon A",
    sihyhg: "HG",
    dkt: 22,
    water: 66,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "SofLens Multi-Focal",
    brand: "SofLens",
    manuf: "Bausch + Lomb",
    schedule: "2W",
    nameID: "BL17",
    toric: false,
    multifocal: true,
    mfOptions: "Low, High",
    color: false,
    multBC: true,
    BC: "8.5, 8.8",
    BC1: "8.5",
    BC2: "8.8",
    diam: "14.5",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -7,
    maxPlus: 6,
    fiftySteps: false,
    bandage: false,
    extended: true,
    extendedNotes: "7 days/6 nights",
    material: "polymacon",
    sihyhg: "HG",
    dkt: 8.4,
    water: 38,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "SofLens Daily Disposable",
    brand: "SofLens",
    manuf: "Bausch + Lomb",
    schedule: "DD",
    nameID: "BL18",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.2",
    pl: false,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -9,
    maxPlus: 6.5,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "hilafilcon B",
    sihyhg: "HG",
    dkt: 22,
    water: 59,
    Uvclass: "none",
    visibilityTint: true,
    notes: "0.50 steps over -6.50",
    discontinued: false,
    ignore: false,
  },
  {
    name: "SofLens Daily Disposable for Astigmatism",
    brand: "SofLens",
    manuf: "Bausch + Lomb",
    schedule: "DD",
    nameID: "BL19",
    toric: true,
    maxCyl: -1.75,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: 8.6,
    diam: "14.2",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -9,
    maxPlus: 0,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "hilafilcon B",
    sihyhg: "HG",
    dkt: 22,
    water: 59,
    Uvclass: "none",
    visibilityTint: true,
    notice: "Discontinued",
    alert: "Discontinued",
    discontinued: true,
    ignore: true,
  },
  {
    name: "Avaira Vitality",
    brand: "Avaira Vitality",
    manuf: "CooperVision",
    schedule: "2W",
    nameID: "CV01",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.4",
    BC1: "8.4",
    diam: "14.2",
    pl: false,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -12,
    maxPlus: 8,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "fanfilcon A",
    sihyhg: "SiHy",
    dkt: 110,
    water: 55,
    Uvclass: "1",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Avaira Vitality toric",
    brand: "Avaira Vitality",
    manuf: "CooperVision",
    schedule: "2W",
    nameID: "CV02",
    toric: true,
    maxCyl: -2.25,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.5",
    BC1: "8.5",
    diam: "14.5",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -10,
    maxPlus: 8,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "fanfilcon A",
    sihyhg: "SiHy",
    dkt: 90,
    water: 55,
    Uvclass: 1,
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Biofinity",
    brand: "Biofinity",
    manuf: "CooperVision",
    schedule: "1M",
    nameID: "CV03",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.0",
    pl: false,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -12,
    maxPlus: 8,
    fiftySteps: true,
    bandage: false,
    extended: true,
    extendedNotes: "7 days/6 nights",
    material: "comfilcon A",
    sihyhg: "SiHy",
    dkt: 160,
    water: 48,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Biofinity XR",
    brand: "Biofinity",
    manuf: "CooperVision",
    schedule: "1M",
    nameID: "CV04",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.0",
    pl: false,
    minusQuarter: false,
    plusQuarter: false,
    maxMinus: -20,
    maxPlus: 15,
    fiftySteps: true,
    bandage: false,
    extended: true,
    extendedNotes: "7 days/6 nights",
    material: "comfilcon A",
    sihyhg: "SiHy",
    dkt: 160,
    water: 48,
    Uvclass: "none",
    visibilityTint: true,
    notes: "-12.50 and up is XR, +8.50 and up is XR",
    discontinued: false,
    ignore: false,
  },
  {
    name: "Biofinity Energys",
    brand: "Biofinity",
    manuf: "CooperVision",
    schedule: "1M",
    nameID: "CV05",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.0",
    pl: false,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -12,
    maxPlus: 8,
    fiftySteps: true,
    bandage: false,
    extended: true,
    extendedNotes: "7 days/6 nights",
    material: "comfilcon A",
    sihyhg: "SiHy",
    dkt: 160,
    water: 48,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Biofinity multifocal",
    brand: "Biofinity",
    manuf: "CooperVision",
    schedule: "1M",
    nameID: "CV06",
    toric: false,
    multifocal: true,
    mfOptions: "1.00, 1.50, 2.00, 2.50; D, N",
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.0",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -10,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: true,
    extendedNotes: "7 days/6 nights",
    material: "comfilcon A",
    sihyhg: "SiHy",
    dkt: 142,
    water: 48,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Biofinity toric",
    brand: "Biofinity",
    manuf: "CooperVision",
    schedule: "1M",
    nameID: "CV07",
    toric: true,
    maxCyl: -2.25,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.7",
    BC1: "8.7",
    diam: "14.5",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -10,
    maxPlus: 8,
    fiftySteps: true,
    bandage: false,
    extended: true,
    extendedNotes: "7 days/6 nights",
    material: "comfilcon A",
    sihyhg: "SiHy",
    dkt: 116,
    water: 48,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Biofinity XR toric",
    brand: "Biofinity",
    manuf: "CooperVision",
    schedule: "1M",
    nameID: "CV08",
    toric: true,
    maxCyl: -5.75,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.7",
    BC1: "8.7",
    diam: "14.5",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -20,
    maxPlus: 20,
    fiftySteps: true,
    bandage: false,
    extended: true,
    extendedNotes: "7 days/6 nights",
    material: "comfilcon A",
    sihyhg: "SiHy",
    dkt: 116,
    water: 48,
    Uvclass: "none",
    visibilityTint: true,
    notes: "XR if -10+ or 8+ and/or if cyl -2.75 to -5.75 ",
    discontinued: false,
    ignore: false,
  },
  {
    name: "Biofinity toric multifocal",
    brand: "Biofinity",
    manuf: "CooperVision",
    schedule: "1M",
    nameID: "CV09",
    toric: true,
    maxCyl: -5.75,
    multifocal: true,
    mfOptions: "1.00, 1.50, 2.00, 2.50; D, N",
    color: false,
    multBC: false,
    BC: "8.7",
    BC1: "8.7",
    diam: "14.5",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -10,
    maxPlus: 10,
    fiftySteps: true,
    bandage: false,
    extended: true,
    extendedNotes: "7 days/6 nights",
    material: "comfilcon A",
    sihyhg: "SiHy",
    dkt: 116,
    water: 48,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Biomedics 55 Premier",
    brand: "Biomedics",
    manuf: "CooperVision",
    schedule: "2W",
    nameID: "CV10",
    toric: false,
    multifocal: false,
    color: false,
    multBC: true,
    BC: "8.6, 8.8, 8.9",
    BC1: "8.6",
    BC2: 8.8,
    BC3: 8.9,
    diam: "14.2",
    pl: false,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -10,
    maxPlus: 8,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "ocufilcon D",
    sihyhg: "HG",
    dkt: 26,
    water: 55,
    Uvclass: 2,
    visibilityTint: true,
    notes: "There is no +5.25 or +5.75, and ranges change by BC ",
    notice: "BC 8.6, 8.9: minus powers; BC 8.8: plus powers",
    discontinued: false,
    ignore: false,
  },
  {
    name: "Biomedics toric",
    brand: "Biomedics",
    manuf: "CooperVision",
    schedule: "2W",
    nameID: "CV11",
    toric: true,
    maxCyl: -2.25,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.7",
    BC1: "8.7",
    diam: "14.5",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -10,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "ocufilcon D",
    sihyhg: "HG",
    dkt: 18,
    water: 55,
    Uvclass: 2,
    visibilityTint: true,
    notes: "There is no +5.25 or +5.75",
    discontinued: false,
    ignore: false,
  },
  {
    name: "clariti 1 day",
    brand: "Clariti",
    manuf: "CooperVision",
    schedule: "DD",
    nameID: "CV12",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.1",
    pl: false,
    minusQuarter: false,
    plusQuarter: false,
    maxMinus: -10,
    maxPlus: 8,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "somofilcon A",
    sihyhg: "SiHy",
    dkt: 86,
    water: 56,
    Uvclass: 2,
    visibilityTint: true,
    discontinued: false,
    ignore: true,
  },
  {
    name: "clariti 1 day toric",
    brand: "Clariti",
    manuf: "CooperVision",
    schedule: "DD",
    nameID: "CV13",
    toric: true,
    maxCyl: -2.25,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.3",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -9,
    maxPlus: 4,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "somofilcon A",
    sihyhg: "SiHy",
    dkt: 57,
    water: 56,
    Uvclass: 2,
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "clariti 1 day multifocal",
    brand: "Clariti",
    manuf: "CooperVision",
    schedule: "DD",
    nameID: "CV14",
    toric: false,
    multifocal: true,
    mfOptions: "Low, High",
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.1",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -6,
    maxPlus: 5,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "somofilcon A",
    sihyhg: "SiHy",
    dkt: 86,
    water: 56,
    Uvclass: 2,
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "MiSight 1 day",
    brand: "MiSight",
    manuf: "CooperVision",
    schedule: "DD",
    nameID: "CV15",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.7",
    BC1: "8.7",
    diam: "14.2",
    pl: false,
    minusQuarter: false,
    plusQuarter: false,
    maxMinus: -7,
    maxPlus: -0.5,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "omafilcon A",
    sihyhg: "SiHy",
    dkt: 28,
    water: 60,
    Uvclass: "none",
    visibilityTint: true,
    notes: "Myopia Management",
    notice: "For myopia management only",
    alert: "Intended for myopia management",
    discontinued: false,
    ignore: true,
  },
  {
    name: "MyDay daily disposable",
    brand: "MyDay",
    manuf: "CooperVision",
    schedule: "DD",
    nameID: "CV16",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.4",
    BC1: "8.4",
    diam: "14.2",
    pl: false,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -12,
    maxPlus: 8,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "stenfilcon A",
    sihyhg: "SiHy",
    dkt: 100,
    water: 54,
    Uvclass: 2,
    visibilityTint: true,
    notes: "There is no +5.25 or +5.75",
    discontinued: false,
    ignore: false,
  },
  {
    name: "MyDay Energys",
    brand: "MyDay",
    manuf: "CooperVision",
    schedule: "DD",
    nameID: "CV17",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.4",
    BC1: "8.4",
    diam: "14.2",
    pl: false,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -12,
    maxPlus: 8,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "stenfilcon A",
    sihyhg: "SiHy",
    dkt: 100,
    water: 54,
    Uvclass: 2,
    visibilityTint: true,
    notes: "There is no +5.25 or +5.75",
    discontinued: false,
    ignore: false,
  },
  {
    name: "MyDay multifocal",
    brand: "MyDay",
    manuf: "CooperVision",
    schedule: "DD",
    nameID: "CV18",
    toric: false,
    multifocal: true,
    mfOptions: "Low, Med, High",
    color: false,
    multBC: false,
    BC: "8.4",
    BC1: "8.4",
    diam: "14.2",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -12,
    maxPlus: 8,
    fiftySteps: false,
    bandage: false,
    extended: false,
    material: "stenfilcon A",
    sihyhg: "SiHy",
    dkt: 100,
    water: 54,
    Uvclass: 2,
    visibilityTint: true,
    notes: "There is no -10.25, -10.75, -11.25, or -11.75 ",
    discontinued: false,
    ignore: false,
  },
  {
    name: "MyDay toric",
    brand: "MyDay",
    manuf: "CooperVision",
    schedule: "DD",
    nameID: "CV19",
    toric: true,
    maxCyl: -2.25,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.5",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -10,
    maxPlus: 8,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "stenfilcon A",
    sihyhg: "SiHy",
    dkt: 80,
    water: 54,
    Uvclass: 2,
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Proclear 1 day",
    brand: "Proclear",
    manuf: "CooperVision",
    schedule: "DD",
    nameID: "CV20",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.7",
    BC1: "8.7",
    diam: "14.2",
    pl: false,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -12,
    maxPlus: 8,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "omafilcon A",
    sihyhg: "SiHy",
    dkt: 28,
    water: 60,
    Uvclass: 2,
    visibilityTint: true,
    notes: "There is no +5.25 or +5.75",
    discontinued: false,
    ignore: false,
  },
  {
    name: "Proclear 1 day Multifocal",
    brand: "Proclear",
    manuf: "CooperVision",
    schedule: "DD",
    nameID: "CV21",
    toric: false,
    multifocal: true,
    mfOptions: "MF",
    color: false,
    multBC: false,
    BC: "8.7",
    BC1: "8.7",
    diam: "14.2",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -10,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "omafilcon A",
    sihyhg: "SiHy",
    dkt: 28,
    water: 60,
    Uvclass: 2,
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Proclear multifocal",
    brand: "Proclear",
    manuf: "CooperVision",
    schedule: "1M",
    nameID: "CV22",
    toric: false,
    multifocal: true,
    mfOptions: "1.00, 1.50, 2.00, 2.50; D, N",
    color: false,
    multBC: false,
    BC: "8.7",
    BC1: "8.7",
    diam: "14.4",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -8,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "omafilcon B",
    sihyhg: "HG",
    dkt: 14,
    water: 62,
    Uvclass: "none",
    visibilityTint: true,
    notes: "There is also -6.25",
    discontinued: false,
    ignore: false,
  },
  {
    name: "Proclear XR multifocal",
    brand: "Proclear",
    manuf: "CooperVision",
    schedule: "1M",
    nameID: "CV23",
    toric: false,
    multifocal: true,
    mfOptions: "1.00, 1.50, 2.00, 2.50, 3.00, 3.50, 4.00; D, N",
    color: false,
    multBC: true,
    BC: "8.4, 8.7",
    BC1: "8.4",
    BC2: "8.7",
    diam: "14.4",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -20,
    maxPlus: 20,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "omafilcon B",
    sihyhg: "HG",
    dkt: 28,
    water: 62,
    Uvclass: "none",
    visibilityTint: true,
    notes:
      "There is also +6.25; +6.25/-6.50 and up and/or BC 8.4 and/or +3.00, +3.50, or +4.00 adds are XR",
    discontinued: false,
    ignore: false,
  },
  {
    name: "Proclear multifocal toric",
    brand: "Proclear",
    manuf: "CooperVision",
    schedule: "2W",
    nameID: "CV24",
    toric: true,
    maxCyl: -5.75,
    multifocal: true,
    mfOptions: "1.00, 1.50, 2.00, 2.50, 3.00, 3.50, 4.00; D, N",
    color: false,
    multBC: true,
    BC: "8.4, 8.8",
    BC1: "8.4",
    BC2: "8.8",
    diam: "14.4",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -20,
    maxPlus: 20,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "omafilcon B",
    sihyhg: "HG",
    dkt: 28,
    water: 62,
    Uvclass: "none",
    visibilityTint: true,
    notes: "There are also -6.25 and +6.25",
    discontinued: false,
    ignore: false,
  },
  {
    name: "Proclear",
    brand: "Proclear",
    manuf: "CooperVision",
    schedule: "1M",
    nameID: "CV25",
    toric: false,
    multifocal: false,
    color: false,
    multBC: true,
    BC: "8.2, 8.6",
    BC1: "8.2",
    BC2: "8.6",
    diam: "14.2",
    pl: true,
    minusQuarter: true,
    plusQuarter: false,
    maxMinus: -20,
    maxPlus: 20,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "omafilcon B",
    sihyhg: "HG",
    dkt: 36,
    water: 62,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Proclear toric",
    brand: "Proclear",
    manuf: "CooperVision",
    schedule: "1M",
    nameID: "CV26",
    toric: true,
    maxCyl: -2.25,
    multifocal: false,
    color: false,
    multBC: true,
    BC: "8.4, 8.8",
    BC1: "8.4",
    BC2: "8.8",
    diam: "14.4",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -8,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "omafilcon B",
    sihyhg: "HG",
    dkt: 19,
    water: 62,
    Uvclass: "none",
    visibilityTint: true,
    notes: "There is also -6.25",
    discontinued: false,
    ignore: false,
  },
  {
    name: "Proclear XR toric",
    brand: "Proclear",
    manuf: "CooperVision",
    schedule: "1M",
    nameID: "CV27",
    toric: true,
    maxCyl: -5.75,
    multifocal: false,
    color: false,
    multBC: true,
    BC: "8.4, 8.8",
    BC1: "8.4",
    BC2: "8.8",
    diam: "14.4",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -10,
    maxPlus: 10,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "omafilcon B",
    sihyhg: "HG",
    dkt: 12,
    water: 62,
    Uvclass: "none",
    visibilityTint: true,
    notes:
      "There are also -6.25 (for XR cyl) and +6.25; +6.25/-6.50  and up and/or -2.75 to -5.75 cyl are XR",
    discontinued: false,
    ignore: false,
  },
  {
    name: "Air Optix plus HydraGlyde",
    brand: "Air Optix",
    manuf: "Alcon",
    schedule: "1M",
    nameID: "A001",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.2",
    pl: false,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -12,
    maxPlus: 8,
    fiftySteps: true,
    bandage: false,
    extended: true,
    extendedNotes: "6 nights",
    material: "lotrafilcon B",
    sihyhg: "SiHy",
    dkt: 138,
    water: 33,
    Uvclass: "none",
    visibilityTint: true,
    notes: "There are also -6.25, -6.75, -7.25, and -7.75",
    discontinued: false,
    ignore: false,
  },
  {
    name: "Air Optix plus HydraGlyde for Astigmatism",
    brand: "Air Optix",
    manuf: "Alcon",
    schedule: "1M",
    nameID: "A002",
    toric: true,
    maxCyl: -2.25,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.7",
    BC1: "8.7",
    diam: "14.5",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -10,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: true,
    extendedNotes: "6 nights",
    material: "lotrafilcon B",
    sihyhg: "SiHy",
    dkt: 110,
    water: 33,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Air Optix plus HydraGlyde Multifocal",
    brand: "Air Optix",
    manuf: "Alcon",
    schedule: "1M",
    nameID: "A003",
    toric: false,
    multifocal: true,
    mfOptions: "LO, MED, HI",
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.2",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -10,
    maxPlus: 6,
    fiftySteps: false,
    bandage: false,
    extended: true,
    extendedNotes: "6 nights",
    material: "lotrafilcon B",
    sihyhg: "SiHy",
    dkt: 124,
    water: 33,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Air Optix Colors",
    brand: "Air Optix",
    manuf: "Alcon",
    schedule: "1M",
    nameID: "A004",
    toric: false,
    multifocal: false,
    color: true,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.2",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -8,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "lotrafilcon B",
    sihyhg: "SiHy",
    dkt: 138,
    water: 33,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Air Optix Night & Day Aqua",
    brand: "Air Optix",
    manuf: "Alcon",
    schedule: "1M",
    nameID: "A005",
    toric: false,
    multifocal: false,
    color: false,
    multBC: true,
    BC: "8.4, 8.6",
    BC1: "8.4",
    BC2: "8.6",
    diam: "13.8",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -10,
    maxPlus: 6,
    fiftySteps: true,
    bandage: true,
    extended: true,
    extendedNotes: "30 nights",
    material: "lotrafilcon A",
    sihyhg: "SiHy",
    dkt: 175,
    water: 24,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Dailies AquaComfort Plus",
    brand: "DailiesACP",
    manuf: "Alcon",
    schedule: "DD",
    nameID: "A006",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.7",
    BC1: "8.7",
    diam: "14.0",
    pl: false,
    minusQuarter: false,
    plusQuarter: false,
    maxMinus: -15,
    maxPlus: 8,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "nelfilcon A",
    sihyhg: "HG",
    dkt: 26,
    water: 69,
    Uvclass: "none",
    visibilityTint: true,
    notice: "To be discontinued",
    alert: "To be discontinued",
    discontinued: false,
    ignore: false,
  },
  {
    name: "Dailies AquaComfort Plus Multifocal",
    brand: "DailiesACP",
    manuf: "Alcon",
    schedule: "DD",
    nameID: "A007",
    toric: false,
    multifocal: true,
    mfOptions: "LO, MED, HI",
    color: false,
    multBC: false,
    BC: "8.8",
    BC1: "8.8",
    diam: "14.4",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -10,
    maxPlus: 6,
    fiftySteps: false,
    bandage: false,
    extended: false,
    material: "nelfilcon A",
    sihyhg: "HG",
    dkt: 26,
    water: 69,
    Uvclass: "none",
    visibilityTint: true,
    notice: "To be discontinued",
    alert: "To be discontinued",
    discontinued: false,
    ignore: false,
  },
  {
    name: "Dailies AquaComfort Plus Toric",
    brand: "DailiesACP",
    manuf: "Alcon",
    schedule: "DD",
    nameID: "A008",
    toric: true,
    maxCyl: -1.75,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.7",
    BC1: "8.7",
    diam: "14.0",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -8,
    maxPlus: 4,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "nelfilcon A",
    sihyhg: "HG",
    dkt: 26,
    water: 69,
    Uvclass: "none",
    visibilityTint: true,
    notice: "To be discontinued",
    alert: "To be discontinued",
    discontinued: false,
    ignore: false,
  },
  {
    name: "Dailies Colors",
    brand: "Dailies Colors",
    manuf: "Alcon",
    schedule: "DD",
    nameID: "A009",
    toric: false,
    multifocal: false,
    color: true,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "13.8",
    pl: true,
    minusQuarter: true,
    plusQuarter: false,
    maxMinus: -8,
    maxPlus: 0,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "nelfilcon A",
    sihyhg: "HG",
    dkt: 26,
    water: 69,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Dailies Total 1",
    brand: "Dailies Total 1",
    manuf: "Alcon",
    schedule: "DD",
    nameID: "A010",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.5",
    BC1: "8.5",
    diam: "14.1",
    pl: false,
    minusQuarter: false,
    plusQuarter: false,
    maxMinus: -12,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "delefilcon A",
    sihyhg: "SiHy",
    dkt: 156,
    water: 33,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Dailies Total 1 for Astigmatism",
    brand: "Dailies Total 1",
    manuf: "Alcon",
    schedule: "DD",
    nameID: "A011",
    toric: true,
    maxCyl: -2.25,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.5",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -8,
    maxPlus: 4,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "delefilcon A",
    sihyhg: "SiHy",
    dkt: 127,
    water: 33,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Dailies Total 1 Multifocal",
    brand: "Dailies Total 1",
    manuf: "Alcon",
    schedule: "DD",
    nameID: "A012",
    toric: false,
    multifocal: true,
    mfOptions: "LO, MED, HI",
    color: false,
    multBC: false,
    BC: "8.5",
    BC1: "8.5",
    diam: "14.1",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -10,
    maxPlus: 6,
    fiftySteps: false,
    bandage: false,
    extended: false,
    material: "delefilcon A",
    sihyhg: "SiHy",
    dkt: 156,
    water: 33,
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Focus Dailies",
    brand: "Focus",
    manuf: "Alcon",
    schedule: "DD",
    nameID: "A013",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "13.8",
    pl: false,
    minusQuarter: false,
    plusQuarter: false,
    maxMinus: -10,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "nelfilcon A",
    sihyhg: "HG",
    dkt: 26,
    water: 69,
    Uvclass: "none",
    visibilityTint: true,
    notice: "Discontinued 9/29/23",
    alert: "Discontinued 9/29/23",
    discontinued: true,
    ignore: true,
  },
  {
    name: "Freshlook Colors",
    brand: "Freshlook",
    manuf: "Alcon",
    schedule: "2W",
    nameID: "A014",
    toric: false,
    multifocal: false,
    color: true,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.5",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -8,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: true,
    extendedNotes: "Not recommended by manufacturer",
    material: "phemfilcon A",
    sihyhg: "HG",
    dkt: 20,
    water: 55,
    Uvclass: "none",
    visibilityTint: true,
    notes:
      "There is no +2.25, +2.75, +3.25, +3.75, +4.25, +4.75, +5.25, or +5.75",
    notice: "Discontinued",
    alert: "Discontinued",
    discontinued: true,
    ignore: true,
  },
  {
    name: "Freshlook Colorblends",
    brand: "Freshlook",
    manuf: "Alcon",
    schedule: "2W",
    nameID: "A015",
    toric: false,
    multifocal: false,
    color: true,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.5",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -8,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: true,
    extendedNotes: "Not recommended by manufacturer",
    material: "phemfilcon A",
    sihyhg: "HG",
    dkt: 20,
    water: 55,
    Uvclass: "none",
    visibilityTint: true,
    notes:
      "There is no +2.25, +2.75, +3.25, +3.75, +4.25, +4.75, +5.25, or +5.75",
    notice: "Discontinued 9/29/23",
    alert: "Discontinued 9/29/23",
    discontinued: true,
    ignore: true,
  },
  {
    name: "Freshlook Handling Tint",
    brand: "Freshlook",
    manuf: "Alcon",
    schedule: "2W",
    nameID: "A016",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.5",
    pl: false,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -8,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: true,
    extendedNotes: "Not recommended by manufacturer",
    material: "phemfilcon A",
    sihyhg: "HG",
    dkt: 20,
    water: 55,
    Uvclass: "none",
    visibilityTint: true,
    notes:
      "There is no +2.25, +2.75, +3.25, +3.75, +4.25, +4.75, +5.25, or +5.75",
    notice: "Discontinued",
    alert: "Discontinued",
    discontinued: true,
    ignore: true,
  },
  {
    name: "Precision1",
    brand: "Precision1",
    manuf: "Alcon",
    schedule: "DD",
    nameID: "A017",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.3",
    BC1: "8.3",
    diam: "14.2",
    pl: false,
    minusQuarter: false,
    plusQuarter: false,
    maxMinus: -12,
    maxPlus: 8,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "verofilcon A",
    sihyhg: "SiHy",
    dkt: 100,
    water: 51,
    Uvclass: 1,
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Precision1 for Astigmatism",
    brand: "Precision1",
    manuf: "Alcon",
    schedule: "DD",
    nameID: "A018",
    toric: true,
    maxCyl: -2.25,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.5",
    BC1: "8.5",
    diam: "14.5",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -8,
    maxPlus: 4,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "verofilcon A",
    sihyhg: "SiHy",
    dkt: 90,
    water: 51,
    Uvclass: 1,
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "TOTAL30",
    brand: "TOTAL30",
    manuf: "Alcon",
    schedule: "1M",
    nameID: "A019",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.4",
    BC1: "8.4",
    diam: "14.2",
    pl: false,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -12,
    maxPlus: 8,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "lehfilcon A",
    sihyhg: "SiHy",
    dkt: 154,
    water: 55,
    Uvclass: 1,
    visibilityTint: true,
    notes: "There are also -6.25, -6.75, -7.25, and -7.75",
    discontinued: false,
    ignore: false,
  },
  {
    name: "TOTAL30 for Astigmatism",
    brand: "TOTAL30",
    manuf: "Alcon",
    schedule: "1M",
    nameID: "A020",
    toric: true,
    maxCyl: -2.25,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.5",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -10,
    maxPlus: 8,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "lehfilcon A",
    sihyhg: "SiHy",
    dkt: 123,
    water: 55,
    Uvclass: 1,
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "TOTAL30 Multifocal",
    brand: "TOTAL30",
    manuf: "Alcon",
    schedule: "1M",
    nameID: "A021",
    toric: false,
    multifocal: true,
    mfOptions: "LO, MED, HI",
    color: false,
    multBC: false,
    BC: 8.4,
    BC1: 8.4,
    diam: 14.2,
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -10,
    maxPlus: 6,
    fiftySteps: false,
    bandage: false,
    extended: false,
    material: "lehfilcon A",
    sihyhg: "SiHy",
    dkt: 154,
    water: 55,
    Uvclass: 1,
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Miru 1day Flat Pack",
    brand: "Miru",
    manuf: "Menicon",
    schedule: "DD",
    nameID: "M001",
    toric: false,
    multifocal: false,
    color: false,
    multBC: true,
    BC: "8.4, 8.6",
    BC1: "8.4",
    BC2: 8.6,
    diam: "14.2",
    pl: false,
    minusQuarter: false,
    plusQuarter: false,
    maxMinus: -10,
    maxPlus: 4,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "hioxifilcon A",
    sihyhg: "SiHy",
    dkt: 25,
    water: "57",
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Miru 1day Flat Pack Toric",
    brand: "Miru",
    manuf: "Menicon",
    schedule: "DD",
    nameID: "M002",
    toric: true,
    maxCyl: -1.75,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.5",
    pl: false,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -8,
    maxPlus: 2,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "hioxifilcon A",
    sihyhg: "SiHy",
    dkt: 25,
    water: "57",
    Uvclass: "none",
    visibilityTint: true,
    notes: "Axes 015, 090, 165, 180; -1.75 is 180 only",
    discontinued: false,
    ignore: false,
  },
  {
    name: "Miru 1day UpSide",
    brand: "Miru",
    manuf: "Menicon",
    schedule: "DD",
    nameID: "M003",
    toric: false,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.4",
    BC1: "8.4",
    diam: "14.2",
    pl: false,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -13,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "midafilcon A",
    sihyhg: "SiHy",
    dkt: 91,
    water: "56",
    Uvclass: "2",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Miru 1day UpSide multifocal",
    brand: "Miru",
    manuf: "Menicon",
    schedule: "DD",
    nameID: "M004",
    toric: false,
    multifocal: true,
    mfOptions: "LOW, HIGH",
    color: false,
    multBC: false,
    BC: "8.4",
    BC1: "8.4",
    diam: "14.2",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -10,
    maxPlus: 5,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "midafilcon A",
    sihyhg: "SiHy",
    dkt: 91,
    water: "56",
    Uvclass: "2",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Miru 1month",
    brand: "Miru",
    manuf: "Menicon",
    schedule: "1M",
    nameID: "M005",
    toric: false,
    multifocal: false,
    color: false,
    multBC: true,
    BC: "8.3, 8.6",
    BC1: "8.3",
    BC2: 8.6,
    diam: "14.0",
    pl: false,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -13,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "asmofilcon A",
    sihyhg: "SiHy",
    dkt: 161,
    water: "40",
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Miru 1month toric",
    brand: "Miru",
    manuf: "Menicon",
    schedule: "1M",
    nameID: "M006",
    toric: true,
    maxCyl: -1.75,
    multifocal: false,
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.0",
    pl: true,
    minusQuarter: true,
    plusQuarter: false,
    maxMinus: -13,
    maxPlus: 0,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "asmofilcon A",
    sihyhg: "SiHy",
    dkt: 161,
    water: "40",
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
  {
    name: "Miru 1month multifocal",
    brand: "Miru",
    manuf: "Menicon",
    schedule: "1M",
    nameID: "M007",
    toric: false,
    multifocal: true,
    mfOptions: "LOW",
    color: false,
    multBC: false,
    BC: "8.6",
    BC1: "8.6",
    diam: "14.2",
    pl: true,
    minusQuarter: true,
    plusQuarter: true,
    maxMinus: -13,
    maxPlus: 6,
    fiftySteps: true,
    bandage: false,
    extended: false,
    material: "asmofilcon A",
    sihyhg: "SiHy",
    dkt: 161,
    water: "40",
    Uvclass: "none",
    visibilityTint: true,
    discontinued: false,
    ignore: false,
  },
];

export default lensArray;
