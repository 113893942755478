import React, { useEffect, useState } from "react";
import PopUpCard from "./PopUpCard";
import CylDisplay from "./CylDisplay";
import AdBar from "./AdBar";

function LensList({
  filteredArray,
  darkMode,
  examMode,
  onLensSelected,
  currentLens,
}) {
  const [selectedLens, setSelectedLens] = useState(null);
  const [noMatchingLenses, setNoMatchingLenses] = useState(false);
  const [showCylDisplay, setShowCylDisplay] = useState(null);
  const [cylDetails, setCylDetails] = useState(null);

  const handleRowClick = (lens) => {
    setSelectedLens(lens);
    onLensSelected(lens.alert, lens.discontinued);
  };

  const handleCylClick = (lens, cyl) => {
    setCylDetails({ lensName: lens.name, manuf: lens.manuf, cyl });
    setShowCylDisplay(true);
  };

  const handleCylDisplayClose = () => {
    setShowCylDisplay(false);
    setCylDetails(null);
  };

  const [isXsmallScreen, setIsXsmallScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMedScreen, setIsMedScreen] = useState(false);

  useEffect(() => {
    function handleResizeXsmall() {
      setIsXsmallScreen(window.innerWidth <= 490);
    }

    window.addEventListener("resize", handleResizeXsmall);
    handleResizeXsmall();
    return () => window.removeEventListener("resize", handleResizeXsmall);
  }, []);

  useEffect(() => {
    function handleResizeSmall() {
      setIsSmallScreen(window.innerWidth > 490 && window.innerWidth <= 640);
    }

    window.addEventListener("resize", handleResizeSmall);
    handleResizeSmall();
    return () => window.removeEventListener("resize", handleResizeSmall);
  }, []);

  useEffect(() => {
    function handleResizeMed() {
      setIsMedScreen(window.innerWidth > 640 && window.innerWidth <= 1115);
    }

    window.addEventListener("resize", handleResizeMed);
    handleResizeMed();
    return () => window.removeEventListener("resize", handleResizeMed);
  }, []);

  useEffect(() => {
    setNoMatchingLenses(filteredArray.length === 0);
  }, [filteredArray]);

  useEffect(() => {
    const firstImageContainer = document.getElementById("firstImageContainer");
    const secondImageContainer = document.getElementById(
      "secondImageContainer"
    );

    if (firstImageContainer && secondImageContainer) {
      const topPosition = firstImageContainer.getBoundingClientRect().bottom;
      secondImageContainer.style.top = `${topPosition}px`;
    }
  }, []);

  const scaleFactor = isMedScreen ? 0.8 : isSmallScreen ? 0.75 : 1;

  const darkModeClass = darkMode && !examMode ? "dark-mode" : "";
  const examModeClass = examMode ? "exam-mode" : "";

  return (
    <>
      <div
        className={`table-container ${darkModeClass} ${examModeClass}`}
        style={{
          marginLeft:
            isXsmallScreen || isSmallScreen
              ? "188px"
              : isSmallScreen
              ? "238px"
              : isMedScreen
              ? "163px"
              : "168px",
          marginRight: isXsmallScreen
            ? "0px"
            : isSmallScreen
            ? "70px"
            : isMedScreen
            ? "148px"
            : "168px",
        }}
      >
        <div className="table-wrapper">
          {noMatchingLenses ? (
            <p className="no-match-message">
              Sorry but there are no lenses that match your criteria.
            </p>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th id="toric-header">T</th>
                  <th id="multifocal-header">MF</th>
                  <th id="replacement-header">
                    {isMedScreen || isSmallScreen || isXsmallScreen
                      ? "RS"
                      : "Schedule"}
                  </th>
                  <th id="bc-header">
                    {isSmallScreen || isXsmallScreen ? "BC" : "Base Curve"}
                  </th>
                  <th id="diam-header">Diam</th>
                </tr>
              </thead>
              <tbody>
                {filteredArray.map((lens) => (
                  <tr key={lens.nameID} onClick={() => handleRowClick(lens)}>
                    <td id="name-column">
                      {lens.manuf === "Vistakon" &&
                      lens.name !== "ACUVUE 2" &&
                      lens.name !== "1-Day ACUVUE TruEye"
                        ? "ACUVUE " + lens.name
                        : lens.name}
                    </td>
                    <td
                      id="toric-column"
                      className={lens.toric ? "circle toric" : ""}
                      data-label={lens.toric ? "T" : ""}
                    ></td>
                    <td
                      id="multifocal-column"
                      className={lens.multifocal ? "circle multifocal" : ""}
                      data-label={lens.multifocal ? "MF" : ""}
                    ></td>
                    <td id="replacement-column">{lens.schedule}</td>
                    <td id="bc-column">{lens.BC}</td>
                    <td id="diam-column">{lens.diam}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {selectedLens && (
            <PopUpCard
              lens={selectedLens}
              darkMode={darkMode}
              onClose={() => setSelectedLens(null)}
              onCylClick={handleCylClick}
              isSmallScreen={isSmallScreen}
              isXsmallScreen={isXsmallScreen}
            />
          )}
          {showCylDisplay && cylDetails && (
            <div>
              {console.log("CylDisplay is rendering")}
              <CylDisplay
                lensName={cylDetails.lensName}
                manuf={cylDetails.manuf}
                cyl={cylDetails.cyl}
                onClose={handleCylDisplayClose}
              />
            </div>
          )}
        </div>

        {!isXsmallScreen && (
          <AdBar
            isMedScreen={isMedScreen}
            isSmallScreen={isSmallScreen}
            isXsmallScreen={isXsmallScreen}
            scaleFactor={scaleFactor}
          />
        )}
      </div>
    </>
  );
}

export default LensList;
