import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import lensArray from "./assets/lensArray";
import privateArray from "./assets/privateArray";
import calculatePossibleSphValues from "./Calculators/SphCalc";
import calculatePossibleCylValues from "./Calculators/CylCalc";
import LensByAxisAlcon from "./Calculators/AxisCalc/LensByAxisAlcon";
import LensByAxisBausch from "./Calculators/AxisCalc/LensByAxisBausch";
import LensByAxisCooper from "./Calculators/AxisCalc/LensByAxisCooper";
import LensByAxisVistakon from "./Calculators/AxisCalc/LensByAxisVistakon";
import LensByAxisMenicon from "./Calculators/AxisCalc/LensByAxisMenicon";
import "./App.css";
import Header from "./Header";
import Footer from "./Footer";
import LensList from "./LensList";
import Sidebar from "./Sidebar";

export default function App() {
  const [filters, setFilters] = useState({
    manuf: "",
    schedule: "",
    power: "",
    toric: false,
    multifocal: false,
    cyl: "",
    axis: "",
    siHyChecked: false,
    hydrogelChecked: false,
    group1: false,
    group2: false,
    group3: false,
    group4: false,
    extended: false,
    color: false,
    bandage: false,
  });

  const handleLensSelected = (
    alertMessage,
    darkMode,
    examMode,
    discontinued
  ) => {
    let theme = "light";
    let toastType = "info";

    if (discontinued) {
      toastType = "error";
    }

    if (examMode) {
      theme = "dark";
    } else if (darkMode) {
      theme = "colored";
    }

    toast[toastType](alertMessage, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: theme,
    });
  };

  const [searchInput, setSearchInput] = useState("");
  const [filteredLenses, setFilteredLenses] = useState([]);
  const [darkMode, setDarkMode] = useState(false);
  const [examMode, setExamMode] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [headerOpacity, setHeaderOpacity] = useState(1);
  const [hideToggles, setHideToggles] = useState(false);
  const [fiveDegreeSteps, setFiveDegreeSteps] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [lenses] = useState(lensArray);
  const [group1, setGroup1] = useState(false);
  const [group2, setGroup2] = useState(false);
  const [group3, setGroup3] = useState(false);
  const [group4, setGroup4] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const newScrollY = window.scrollY;
      const newHeaderOpacity = newScrollY > 100 ? 0.5 : 1;
      setScrollY(newScrollY);
      setHeaderOpacity(newHeaderOpacity);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setHideToggles(window.innerWidth <= 767);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setCollapsed(window.innerWidth <= 767);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const filteredLenses = lenses.filter((lens) => {
      const {
        manuf,
        schedule,
        power,
        toric,
        multifocal,
        cyl,
        axis,
        extended,
        color,
        bandage,
      } = filters;

      const cleanedPower = power.replace(/\+/g, "");
      const possibleSphValues = calculatePossibleSphValues({ ...lens, cyl });
      const possibleCylValues = calculatePossibleCylValues(lens);

      const axisFilteredLenses = [
        ...LensByAxisAlcon({ power: cleanedPower, axis }),
        ...LensByAxisBausch({ power: cleanedPower, axis, cyl }),
        ...LensByAxisCooper({ power: cleanedPower, axis, cyl }),
        ...LensByAxisVistakon({ power: cleanedPower, axis, cyl }),
        ...LensByAxisMenicon({ power: cleanedPower, axis, cyl }),
      ];
      const axisMatch = axisFilteredLenses.includes(lens.name);

      const sihyhgMatch =
        (!filters.siHyChecked || lens.sihyhg === "SiHy") &&
        (!filters.hydrogelChecked || lens.sihyhg === "HG");

      const lensName = lens.name.toLowerCase();

      const namesToSearch = [
        lensName,
        ...privateArray
          .filter((obj) => obj.Original.toLowerCase() === lensName)
          .map((obj) => obj.Private.toLowerCase()),
      ];

      const anyGroupChecked = group1 || group2 || group3 || group4;

      const dktMatch =
        !anyGroupChecked ||
        (group1 && lens.dkt < 27) ||
        (group2 && lens.dkt >= 27 && lens.dkt < 87) ||
        (group3 && lens.dkt >= 87 && lens.dkt <= 141) ||
        (group4 && lens.dkt > 141);

      return (
        (searchInput === "" ||
          namesToSearch.some((name) =>
            name.includes(searchInput.toLowerCase())
          ) ||
          lens.manuf.toLowerCase().includes(searchInput) ||
          lens.schedule.toLowerCase().includes(searchInput) ||
          lens.name.toLowerCase().includes(searchInput)) &&
        (!manuf || manuf === lens.manuf) &&
        (!schedule || schedule === lens.schedule) &&
        (!power || possibleSphValues.includes(cleanedPower)) &&
        (!cyl || (lens.toric && possibleCylValues.includes(cyl))) &&
        (!toric || lens.toric) &&
        (!multifocal || lens.multifocal) &&
        (!extended || lens.extended) &&
        (!color || lens.color) &&
        (!bandage || lens.bandage) &&
        (!axis || axisMatch) &&
        sihyhgMatch &&
        dktMatch
      );
    });
    setFilteredLenses(filteredLenses);
    console.log("Filtered Lenses2:", filteredLenses);
  }, [filters, searchInput, group1, group2, group3, group4, lenses]);

  const handleFilterChange = (e) => {
    const { name, value, checked, type } = e.target;

    if (name === "search") {
      setSearchInput(value.toLowerCase());
    } else {
      let newValue = type === "checkbox" ? checked : value;
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: newValue,
      }));
    }
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const toggleExamMode = () => {
    setExamMode(!examMode);
  };

  const toggleCollapsed = () => {
    if (window.innerWidth <= 767) {
      setCollapsed(!collapsed);
    }
  };

  // Function to handle checkbox changes
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    if (name === "siHyChecked" || name === "hydrogelChecked") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: checked,
      }));
    } else if (name === "group1") {
      setGroup1(checked);
      setFilters((prevFilters) => ({
        ...prevFilters,
        group1: checked,
      }));
    } else if (name === "group2") {
      setGroup2(checked);
      setFilters((prevFilters) => ({
        ...prevFilters,
        group2: checked,
      }));
    } else if (name === "group3") {
      setGroup3(checked);
      setFilters((prevFilters) => ({
        ...prevFilters,
        group3: checked,
      }));
    } else if (name === "group4") {
      setGroup4(checked);
      setFilters((prevFilters) => ({
        ...prevFilters,
        group4: checked,
      }));
    } else if (name === "extended") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        extended: checked,
      }));
    } else if (name === "color") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        color: checked,
      }));
    } else if (name === "bandage") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        bandage: checked,
      }));
    }
  };

  const handleFiveDegreeStepsChange = (e) => {
    setFiveDegreeSteps(e.target.checked);
  };

  const handleSearchInputChange = (event) => {
    const searchInput = event.target.value.toLowerCase();
    setSearchInput(searchInput);
  };

  const additionalClass = examMode ? "exam-mode" : "";
  const containerClass = `container ${
    darkMode && !examMode ? "dark-mode" : ""
  }`;

  const mainContentStyle = {
    marginLeft: collapsed && window.innerWidth <= 767 ? "-200px" : "0",
    transition: "margin-right 0.5s ease-in-out",
  };

  return (
    <div
      className={`${containerClass} ${additionalClass}`}
      id="parentContainer"
    >
      <ToastContainer />
      {!hideToggles && (
        <>
          <div
            className="toggle-container"
            style={{ opacity: scrollY > 100 ? 0.5 : 1 }}
          >
            <label className="switch">
              <input
                type="checkbox"
                onChange={toggleDarkMode}
                checked={darkMode}
              />
              <span className="slider round"></span>
            </label>
            <span className={`mode-text ${darkMode ? "dark" : "light"}`}>
              {darkMode ? "Dark Mode" : "Light Mode"}
            </span>
          </div>{" "}
          <div
            className="toggle-container2"
            style={{ opacity: scrollY > 100 ? 0.5 : 1 }}
          >
            <label className="switch">
              <input
                type="checkbox"
                onChange={toggleExamMode}
                checked={examMode}
              />
              <span className="slider round"></span>
            </label>
            <span className={`mode-text ${darkMode ? "dark" : "light"}`}>
              Exam Mode
            </span>
          </div>
        </>
      )}
      <div className={`app ${darkMode ? "dark-mode" : ""}`}>
        <Sidebar
          filters={filters}
          handleFilterChange={handleFilterChange}
          handleCheckboxChange={handleCheckboxChange}
          setFilters={setFilters}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          toggleCollapsed={toggleCollapsed}
          lenses={lenses}
          fiveDegreeSteps={fiveDegreeSteps}
          setFiveDegreeSteps={setFiveDegreeSteps}
          handleFiveDegreeStepsChange={handleFiveDegreeStepsChange}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          handleSearchInputChange={handleSearchInputChange}
          materialOptions={["SiHy", "Hydrogel"]}
          setGroup1={setGroup1}
          setGroup2={setGroup2}
          setGroup3={setGroup3}
          setGroup4={setGroup4}
        />
      </div>
      <div className="main" style={mainContentStyle}>
        <Header opacity={headerOpacity} collapsed={collapsed} />
        <div className={`main ${!collapsed && hideToggles ? "no-click" : ""}`}>
          <div
            className={`main ${!collapsed && hideToggles ? "no-click" : ""}`}
          >
            <LensList
              filteredArray={filteredLenses}
              darkMode={darkMode}
              onLensSelected={(alertMessage, discontinued) =>
                handleLensSelected(
                  alertMessage,
                  darkMode,
                  examMode,
                  discontinued
                )
              }
            />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
