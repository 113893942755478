import React, { useEffect, useRef } from "react";
import AxisDisplay from "./AxisDisplay";

const CylDisplay = ({ lensName, manuf, cyl, onClose }) => {
  const cylDisplayRef = useRef(null);
  let message = "";

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        cylDisplayRef.current &&
        !cylDisplayRef.current.contains(event.target)
      ) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  switch (lensName) {
    case "PureVision2 for Astigmatism":
      message =
        "Plus powers for all axes are discontinued; minus powers for 030-070 and 110-150 are discontinued";
      break;
    case "SofLens Toric":
      message = "Plano and all plus powers are discontinued";
      break;
    default:
      message = "";
      break;
  }

  return (
    <div ref={cylDisplayRef}>
      <div className="cyl-display">
        <div className="cyl-content">
          <span className="close" onClick={onClose}>
            &times;
          </span>
          <div>
            <h4 style={{ marginTop: "1px" }}>{lensName}</h4>
          </div>
          <div>
            <span style={{ display: "inline-block" }}>
              <h4 style={{ marginTop: "1px" }}>Cyl:</h4>
            </span>
            <span style={{ display: "inline-block" }}>&nbsp;{cyl}</span>
          </div>
          <hr />
          <h4 style={{ marginTop: "5px" }}>Available axes:</h4>
          <AxisDisplay lensName={lensName} manuf={manuf} cyl={cyl} />
          {message && (
            <>
              <hr style={{ marginTop: "17px", marginBottom: "16px" }} />
              <p>{message}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CylDisplay;
